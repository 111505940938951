.slide {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* 자식 요소들을 세로 방향으로 정렬 */
}

.slider {
  width: 100%;
}

.slick-slide {
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* 자식 요소들을 세로 방향으로 정렬 */
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* 부모 요소인 .slick-slide의 너비를 따라감 */
  height: 100%; /* 부모 요소인 .slick-slide의 높이를 따라감 */
}

/* 이미지 스타일 */
.icon-image {
  width: 100%; /* 이미지를 부모 요소인 .icon의 너비에 맞춤 */
  height: auto; /* 이미지를 부모 요소인 .icon의 높이에 맞춤 */
  object-fit: contain; /* 이미지가 잘리지 않고 부모 요소에 맞춰짐 */
  cursor: pointer; /* 커서를 포인터로 변경 */
  background-color: #ffffff;
}

.slide .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* 버튼 컨테이너의 너비를 100%로 설정하여 가로 전체를 차지하도록 함 */
}

.button-container button {
  padding: 8px 20px; /* 버튼 내부 여백 */
  font-size: 18px; /* 버튼 텍스트 크기 */
  cursor: pointer;
  border: 1px solid #ffffff; /* 테두리 스타일 */
  background-color: transparent; /* 배경색 */
  color: #ffffff; /* 텍스트 색상 */
  transition: all 0.3s ease;
}

.button-container button:hover {
  background-color: #ffffff; /* 호버 시 배경색 */
}

.footer_top_box_m2 {
  height: 175px; /* 원하는 높이로 조정 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_top_box_m2 .slide {
  width: 70%; /* 이미지 슬라이드 영역의 너비 */
  margin: 0 auto; /* 가운데 정렬 */
}

.footer_top_box_m2 .button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.button-container button svg {
  width: 20px;
  height: 20px;
  fill: #333; /* 아이콘 색상 */
}
