/* Popup.css */

/* 팝업 컨테이너 기본 스타일 */
.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  width: 70%; /* 팝업 이미지 슬라이더의 너비 설정 */
  height: 100vh; /* 팝업 이미지 슬라이더의 높이 설정 */
  min-width: 80%; /* 최소 너비 설정 */
  margin: auto; /* 가운데 정렬을 위한 마진 설정 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 슬릭 슬라이더 */
.slick-slider {
  width: 100% !important;
  z-index: 1;
}

/* 팝업 */
.popup {
  width: 100%; /* 각 팝업 이미지의 너비 설정 */
  height: auto; /* 각 팝업 이미지의 높이 자동 설정 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup img {
  max-width: 100%; /* 이미지의 최대 너비 설정 */
  max-height: 100%; /* 이미지의 최대 높이 설정 */
  border-radius: 20px;
}

/* 팝업 버튼 */
.popup-buttons {
  margin-top: 50px;
  text-align: center;
  position: relative;
  z-index: 1003;
}

.popup-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #4faaa1;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.popup-buttons button:hover {
  background-color: #0056b3;
}

/* 태블릿 (1024px 이하) */
@media (max-width: 1024px) {
  .popup-container {
    /* padding: 180px; */
    padding: 100px;
  }
  .popup-content {
    width: 600px;
  }
}

/* 모바일 (767px 이하) */
@media (max-width: 767px) {
  .popup-container {
    /* padding: 120px; */
    padding: 70px;
  }
  .popup-content {
    width: 350px;
    min-width: 60%;
  }
  .popup-buttons {
    margin-top: 30px;
  }

  .popup-buttons button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

/* 모바일 (500px 이하) */
@media (max-width: 500px) {
  .popup-container {
    padding: 50px;
  }
  .popup-content {
    width: 280px;
  }
  .popup-buttons {
    margin-top: 30px;
  }

  .popup-buttons button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

/* 모바일 (400px 이하) */
@media (max-width: 400px) {
  .popup-container {
    padding: 20px;
  }
  .popup-content {
    width: 240px;
  }
  .popup-buttons {
    margin-top: 30px;
  }

  .popup-buttons button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

.custom-arrow.custom-prev.slick-arrow.slick-prev:before {
  font-size: 40px;
}

.slick-prev {
  /* left: -35px; */
  left: -50px;
}

.custom-arrow.custom-next.slick-arrow.slick-next:before {
  font-size: 40px;
}

.slick-next {
  /* right: -35px; */
  right: -30px;
}

/* 슬라이드 중앙 정렬 */
/* .slick-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slide {
  flex: 0 0 calc(100% / 3);
  max-width: calc(100% / 3);
  box-sizing: border-box;
  padding: 0 10px;
}

.slick-slide.slick-active {
  margin-left: auto;
  margin-right: auto;
} */
