/* APPLE */
@font-face {
  font-family: "AppleSD_R";
  src: url("../font/AppleSDGothicNeoR.ttf");
}

/* 제주고딕 */
@font-face {
  font-family: "Jeju";
  src: url("../font/JejuGothic.ttf");
}

/* 김정철 명조*/
@font-face {
  font-family: "Kimjungchul_R";
  src: url("../font/KimjungchulMyungjo-Regular.ttf");
}

/* 나눔 명조*/
@font-face {
  font-family: "NanumM";
  src: url("../font/NanumMyeongjo.ttf");
}

/* 나눔 스퀘어*/
@font-face {
  font-family: "NanumS_R";
  src: url("../font/NanumSquareR.ttf");
}

/* pretendard */

@font-face {
  font-family: "Pretendard_m";
  src: url("../font/Pretendard-Medium.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Pretendard_b";
  src: url("../font/Pretendard-SemiBold.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Pretendard_bb";
  src: url("../font/Pretendard-Bold.otf");
  font-weight: 700;
}
@font-face {
  font-family: "Pretendard_bbb";
  src: url("../font/Pretendard-ExtraBold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Pretendard_l";
  src: url("../font/Pretendard-Light.otf");
  font-weight: 300;
}
@font-face {
  font-family: "Pretendard_ll";
  src: url("../font/Pretendard-ExtraLight.otf");
  font-weight: 200;
}

/* 국민연금체 */

@font-face {
  font-family: "Nps_m";
  src: url("../font/NPSfont_bold.ttf");
}

/* 느림보고딕 */

@font-face {
  font-family: "neurimbo";
  src: url("../font/neurimboGothicRegular.otf");
}
@font-face {
  font-family: "kcc";
  src: url("../font/kcc.woff");
}
