@import "hoseoUtil.scss";

@media (max-width: 1500px) {
  .result_content_title {
    font-size: 22px !important;
  }
  .quick-menu {
    display: none;
  }
  .board_wrap .board_back .board_container {
    width: 80%;
  }
  .board_wrap .board_back .board_container .list_area {
    padding-bottom: 100px;
  }
  .login_wrap {
    height: calc(100vh - 307px);
  }

  .empty_wrap {
    height: calc(100vh - 307px);
  }

  .header_area {
    .header_wrap {
      .menu_show_area {
        .menu_show_box.web {
          display: none !important;
        }
      }

      .header_back {
        width: 90%;
      }

      .header_top_box {
        padding: 10px 0;

        .top_small_menu {
          font-size: 10px !important;
        }
      }

      .header_bottom_box {
        padding: 12px 0 !important;

        .logo_box {
          width: 115px !important;

          .logo_img {
            height: 30px !important;
          }
        }

        .right_box {
          width: calc(100% - 115px) !important;

          .menu_box {
            display: none !important;
          }

          .icon_box {
            display: flex;
            border-left: none !important;
            float: right;
            .menu_icon {
              width: 34px;
              height: 34px;
              order: 2;
              &.list {
                margin-left: 10px !important;
              }

              &.web {
                display: none;
              }

              &.mobile {
                display: inline-block;
              }
            }
            .search_icon_box {
              display: flex;
              order: 1;
              .search_icon {
                width: 34px;
                height: 34px;
              }
            }
          }
        }
      }
    }
  }

  .footer_area {
    .footer_back {
      .footer_top_box {
        // padding: 20px 10px;

        .footer_icon {
          width: 21px;
          height: 15px;

          &.insta {
            width: 20px;
            height: 20px;
          }
        }
      }

      .footer_bottom_box {
        padding: 32px 10px 40px;

        .bottom_first_info {
          padding-bottom: 16px !important;

          .line_text {
            font-size: 9px;
            border-right: 1px solid #fff;
          }
        }

        .bottom_second_info {
          font-size: 10px;
          line-height: 15px;
          padding-bottom: 16px;
        }

        .bottom_copy {
          font-size: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 1380px) {
    .sub_wrap
      .sub_back
      .sub_main_area.intro
      .intro_top_box
      .intro_box
      .right_top_box
      .image_box
      .intro_image {
      width: 280px !important;
      height: 280px !important;
    }
    // .intro_top_box {
    //   text-align: center;
    // }
    .sub_wrap .sub_back .sub_main_area .intro_top_box .intro_box.left {
      width: 100% !important;
    }
    .sub_wrap
      .sub_back
      .sub_main_area
      .intro_top_box
      .intro_box
      .left_bottom_box {
      padding-top: 40px !important;
    }
    .sub_wrap .sub_back .sub_main_area .intro_top_box .intro_box.right {
      padding-bottom: 40px !important;
    }
  }

  .main_area {
    padding-top: 58px;
    background-color: #f8fafa;

    .main_back {
      .scroll_box {
        right: 10px;

        .scroll_icon {
          width: 45px;
          height: 45px;
        }
      }

      .main_banner_box {
        height: 35.2vw;
        min-height: 177px;

        .slide_wrap {
          .swiper {
            .swiper_slide {
              .slide_image_box {
                .slide_text_box {
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  bottom: auto;

                  .banner_logo_box {
                    display: block;
                    padding-right: 0;
                    padding-bottom: 16px;

                    .banner_logo {
                      margin: 0 auto;
                      width: 63px;
                      height: 63px;
                    }
                  }

                  .banner_text {
                    font-size: 13px;
                    line-height: 20px;
                    width: 290px;
                    border-left: none;
                    padding-left: 0;
                    text-align: center;
                  }
                  .banner_txt_sub1 {
                    text-align: center !important;
                  }
                  .banner_txt_sub2 {
                    text-align: center !important;
                  }
                }
              }
            }
          }
        }
      }

      .home_menu_container {
        padding: 18px 0;
        border-bottom: none;

        .home_menu_box {
          width: 90%;
          overflow: auto;
          white-space: nowrap;

          &::-webkit-scrollbar {
            display: none;
          }

          .home_menu_row {
            min-width: 70px;

            .home_menu_icon {
              width: 50px;
              height: 70px;

              &.sixth {
                width: 70px;
              }
            }
          }
        }
      }

      .first_container {
        padding: 10px 0 20px;

        .first_back {
          width: 90%;

          .first_row {
            display: block;
            width: 100%;
            padding: 20px;
            min-height: 337.5px;

            &:last-child {
              margin-left: 0;
              margin-top: 10px;
            }

            .row_top_box {
              padding-bottom: 13px;

              .row_title {
                font-size: 13px;
                width: calc(100% - 65px);
              }

              .row_btn_box {
                .more_btn {
                  font-size: 10px;
                }
              }
            }

            .contents_row {
              padding: 10px 0;

              .contents_title {
                font-size: 13px;
                padding-bottom: 8px;
                height: auto !important;

                .new_btn {
                  font-size: 10px;
                  vertical-align: middle;
                }
              }

              .contents_text {
                font-size: 10px;
                height: 25px;
              }

              .contents_date {
                font-size: 10px;
                padding-top: 6px;
              }
            }
          }
        }
      }

      .middle_banner_box {
        height: 70px;

        .banner_text_box {
          .banner_title {
            font-size: 13px;
            padding-bottom: 7px;
          }

          .banner_sub_text {
            display: none;
          }

          .more_btn {
            font-size: 10px;
          }
        }
      }

      .second_container {
        padding: 17px 0;

        .second_back {
          width: 90%;
          overflow: auto;
          white-space: nowrap;
          padding-bottom: 15px;

          &::-webkit-scrollbar {
            display: none;
          }

          .second_row {
            min-width: 128px;
            width: calc((100% - 18px) / 4);
            margin-right: 6px;

            .row_image {
              height: 78px;
            }

            .row_bottom_box {
              padding: 10px;
              white-space: break-spaces;
              height: auto;
              min-height: 120px;
              max-height: 120px;

              .row_title {
                font-size: 10px;
                padding-bottom: 4px;
                overflow: hidden;
                //white-space: nowrap;
                text-overflow: ellipsis;
              }

              .row_text {
                font-size: 10px;
                height: 25px;
              }

              .more_btn {
                font-size: 7px;
                bottom: 5px !important;
              }
            }
          }
        }
      }

      .third_container {
        padding: 38px 0 50px;

        .third_back {
          width: 90%;

          .third_top_box {
            padding-bottom: 25px;

            .third_title {
              font-size: 13px;
              padding-bottom: 7px;
            }

            .more_btn {
              font-size: 10px;
            }
          }

          .third_contents_box {
            overflow: auto;
            white-space: nowrap;
            padding-bottom: 15px;

            &::-webkit-scrollbar {
              display: none;
            }

            .third_row {
              min-width: 196px;
              width: calc((100% - 21px) / 4);
              margin-right: 7px;

              .title_box {
                font-size: 12px;
                padding: 9px 0;
              }

              .contents_box {
                padding: 15px;

                &::after {
                  width: 25px;
                  height: 26px;
                  top: 15px;
                  right: 15px;
                }

                .contents_text {
                  font-size: 10px;
                  padding-bottom: 7px;
                }
              }
            }
          }
        }
      }
    }
  }

  .sub_wrap {
    .sub_back {
      .sub_main_area {
        .sub_main_back {
          .vision_container {
            .vision_contents_box {
              .second_box {
                .vision_row {
                  &.first {
                    &.three {
                      &::after {
                        display: none;
                      }
                    }
                  }
                }

                .middle_box {
                  &.education {
                    .vision_row_box {
                      width: 100%;

                      .vision_row {
                        &.second {
                          &.one {
                            &::before {
                              display: none;
                            }
                          }
                        }

                        &.bottom {
                          .bottom_title {
                            font-size: 12px;
                          }

                          .bottom_text_row {
                            font-size: 12px;
                          }
                        }
                      }
                    }
                  }
                }
              }

              &.top {
                .first_box {
                  display: block;
                  width: 100%;

                  &:last-child {
                    padding-left: 0;
                  }
                }
              }

              &.center {
                .first_box {
                  width: 100%;
                }

                .second_box {
                  .top_box {
                    border-bottom: none;

                    &::after {
                      display: none;
                    }

                    &::before {
                      display: none;
                    }
                  }
                }

                .vision_row_box {
                  display: block;
                  text-align: center;
                  width: 100%;
                  padding-bottom: 28px;
                  margin-right: 0;

                  .vision_row {
                    &.second {
                      &::after {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .board_wrap {
    .board_back {
      .board_container {
        .list_area {
          .list_box {
            .company_table {
              .company_row {
                width: calc((100% - 14px) / 3);
                margin-right: 7px;

                .title_box {
                  font-size: 12px;
                  padding: 9px 0;
                }

                .contents_box {
                  padding: 15px;
                  min-height: 100px;

                  &::after {
                    width: 25px;
                    height: 26px;
                    top: 15px;
                    right: 15px;
                  }

                  .contents_text {
                    font-size: 10px;
                    padding-bottom: 7px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .sub_main_back {
    width: 60% !important;
  }
  .contents_text_bbs {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .detail_wrap .detail_back .detail_top_box .detail_title {
    font-size: 25px !important;
  }

  // .board_wrap
  //   .board_back
  //   .board_container
  //   .facility_box
  //   .facility_first
  //   .facility_right
  //   .facility_inner_box
  //   .facility_view
  //   .facility_view_content {
  //   .facility_data {
  //     font-size: 20px;
  //   }
  // }
  .board_wrap
    .board_back
    .board_container
    .facility_box
    .facility_first
    .facility_right
    .facility_inner_box
    .facility_view
    .date_view_content
    .date_box
    .date_start_data,
  .board_wrap
    .board_back
    .board_container
    .facility_box
    .facility_first
    .facility_right
    .facility_inner_box
    .facility_view
    .date_view_content
    .date_box
    .date_end_data {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .main_area
    .main_back
    .first_container
    .first_back
    .last_rows
    .row_top_box
    .row_btn_box
    .more_btn {
    font-size: 15px !important;
  }
  .main_area
    .main_back
    .first_container
    .first_back
    .last_rows
    .contents_row
    .contents_text {
    font-size: 13px !important;
    height: 48.5px !important;
  }
  .contents_text_bbs table {
    width: 100%;
    display: block;
    overflow-x: auto;
  }

  .contents_text_bbs td,
  .contents_text_bbs th {
    white-space: nowrap;
  }
}

@media (max-width: 1000px) {
  // .sub_main_back.intro {
  //   width: 80% !important;
  // }
  .sub_wrap .sub_back .sub_main_area.founded {
    padding: 50px 0 0;
  }
  .sub_wrap
    .sub_back
    .sub_main_area
    .vision_container
    .founded_img_box
    .organization_img {
    height: 80vh !important;
  }

  .sub_wrap {
    .sub_back {
      .sub_banner_area {
        .sub_banner_back {
          width: 90%;
          padding: 50px 0 !important;
        }
      }

      .navi_area {
        .navi_back {
          width: 90%;
        }
      }

      .sub_main_area {
        &.intro {
          padding: 50px 0;
        }

        &.vision {
          padding: 50px 0;
        }

        &.history {
          padding: 50px 0;
        }

        .sub_main_back {
          width: 90%;

          .intro_top_box {
            .intro_box {
              &.left {
                width: calc(100% - 150px);
              }

              &.right {
                width: 150px;
              }

              .blank_box {
                height: 150px;
              }

              .left_top_box {
                .logo_box {
                  .intro_logo {
                    width: 50px;
                    height: 50px;
                  }
                }

                .intro_title {
                  font-size: 20px;
                }
              }

              .left_bottom_box {
                .color_box {
                  width: 7px;
                  height: 7px;
                }
              }
            }
          }

          .intro_bottom_box {
            .intro_main_text {
              font-size: 20px;
              line-height: 40px;
              padding-bottom: 30px;
            }

            .intro_sub_text_row {
              font-size: 13px;
              line-height: 30px;
              padding-bottom: 30px;
            }
          }

          .intro_sign_box {
            .sign_box {
              &.right {
                width: 150px;
              }

              &.left {
                width: calc(100% - 150px);
              }

              .sign_text_box {
                font-size: 15px;
              }

              .sign_img {
                height: 80px;
              }
            }
          }

          .history_container {
            border-left: 3px solid $main-color;

            &::before {
              width: 10px;
              height: 10px;
              left: -6px;
              border: 2px solid $main-color;
            }

            &::after {
              width: 10px;
              height: 10px;
              left: -6px;
              border: 2px solid $main-color;
            }

            .history_contents_box {
              padding: 60px 0 0 20px;

              .history_top_box {
                padding-bottom: 15px;

                .logo_box {
                  width: 50px;

                  .logo_img {
                    height: 50px;
                  }
                }

                .history_title_box {
                  .title_text {
                    font-size: 20px;
                    line-height: 30px;
                  }
                }
              }

              .history_contents_row {
                padding-top: 50px;

                &::after {
                  width: 10px;
                  height: 10px;
                  top: 55px;
                  left: -26px;
                }

                .year_text {
                  font-size: 20px;
                  padding-right: 10px;
                }

                .year_contents_box {
                  padding-left: 10px;
                  width: calc(100% - 60px);

                  .contents_row {
                    .contents_text {
                      font-size: 13px;
                      width: calc(100% - 70px);
                    }
                    .contents_text p {
                      font-size: 13px;
                    }

                    .sub_year {
                      font-size: 13px;
                      width: 70px;
                    }
                  }
                }
              }
            }
          }

          .vision_container {
            .founded_img_box {
              &.vision {
                padding: 50px 0 100px;
              }

              &.enterprise_one {
                padding: 50px 0 20px;
              }

              &.formation_one {
                padding: 20px 0;
              }

              &.formation_two {
                padding: 0 0 50px;
              }

              .founded_img {
                height: 31vw;

                &.second {
                  height: 27vw;
                }

                &.third {
                  height: 39vw;
                }

                &.fourth {
                  height: 34vw;
                }

                &.fifth {
                  height: 64vw;
                }

                &.sixth {
                  height: 97vw;
                }

                &.seventh {
                  height: 48vw;
                }

                &.eighth {
                  height: 56vw;
                }

                &.nineth {
                  height: 68vw;
                }

                &.enterprise_one {
                  height: 63vw;
                }

                &.enterprise_two {
                  height: 39vw;
                }

                &.human {
                  height: 70vw;
                }

                &.formation_one {
                  height: 14vw;
                }

                &.formation_two {
                  height: 51vw;
                }

                &.formation_three {
                  height: 52vw;
                }
              }
            }

            .founded_text_box {
              padding: 0 0 40px;

              &.formation {
                padding: 50px 0;
              }

              .founded_text_row {
                font-size: 15px;
                line-height: 30px;
                padding-bottom: 20px;

                .text_title {
                }

                .text_box {
                  &.small {
                    font-size: 12px;
                    line-height: 20px;
                  }
                }
              }
            }

            .vision_contents_box {
              &.top {
                .vision_row {
                  font-size: 12px;

                  &.second {
                    &::after {
                      height: 175px;
                    }
                  }

                  &.third {
                    &::after {
                      height: 175px;
                    }
                  }
                }
              }

              &.center {
                .vision_row {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .board_wrap {
    .board_back {
      .navi_area {
        .navi_back {
          width: 90%;
          //margin-top: 30px;

          .navi_box {
            width: 120px;
            padding: 9px 10px;
          }
        }
      }

      .board_container {
        width: 90%;

        .title_box {
          .navi_text {
            font-size: 12px;
          }

          .title_text {
            font-size: 20px;
          }

          .tab_area {
            .tab_box {
              font-size: 12px;
            }
          }
        }

        .inquiry_area {
          .inquiry_img_box {
            .inquiry_img {
              width: 100%;
              height: 80vw;

              &.bottom {
                height: 14vw;
              }
            }
          }

          .inquiry_top_box {
            .top_title_box {
              padding: 30px 0;

              .top_main_title {
                color: #707070;
                font-size: 20px;
              }

              .top_main_text {
                padding-top: 15px;
                font-size: 15px;
                line-height: 20px;
              }
            }

            .top_contents_box {
              .contents_text_box {
                padding-bottom: 18px;

                .contents_text_row {
                  padding-bottom: 10px;
                  font-size: 15px;
                }
              }

              .inquiry_table_box {
                .inquiry_table {
                  height: 25vw;
                }

                &.bottom {
                }
              }
            }

            .top_info_box {
              width: 100%;
              text-align: center;

              &.right {
                padding-top: 50px;
              }

              .info_title {
                font-size: 20px;
                padding-bottom: 30px;
              }

              .info_text_box {
                .info_row {
                  .info_title {
                    font-size: 12px;
                    padding: 30px 0 10px;
                  }

                  .info_text {
                    font-size: 12px;
                    line-height: 20px;
                  }
                }
              }
            }
          }

          .inquiry_bottom_box {
            .bottom_row {
              font-size: 15px;
              padding-bottom: 15px;
            }
          }
        }

        .list_area {
          .list_box {
            .board_table {
              .table_head {
                .head_section {
                  font-size: 12px;
                }
              }

              .table_body {
                .body_row {
                  .body_section {
                    // font-size: 12px;
                    font-size: 15px;

                    .state_icon {
                      font-size: 10px;
                      padding: 4px 5px;
                    }
                  }
                }
              }
            }

            .img_table {
              .img_row {
                width: calc((100% - 35px) / 2);

                &:nth-child(3n) {
                  margin-right: 35px;
                }

                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }
          }

          .pagination_box {
            .arrow_btn {
              font-size: 12px;
              padding: 4px 8px;
            }

            .page_number {
              font-size: 12px;
              padding: 4px 8px;
            }
          }
        }
      }
    }
  }

  .detail_wrap {
    .detail_back {
      width: 90%;
      overflow-x: hidden;
    }
  }

  .header_area
    .header_wrap
    .header_bottom_box
    .right_box
    .icon_box
    .search_icon_box {
    .search_form {
      position: fixed;
      top: 0;
      width: 100%;
      margin: 10px 0;
      border-radius: 0;
    }
  }
}

@media (max-width: 500px) {
  .sub_wrap .sub_back .sub_main_area .sub_main_back {
    width: 90% !important;
  }
  .sub_wrap
    .sub_back
    .sub_main_area.intro
    .intro_top_box
    .intro_box
    .right_top_box
    .image_box
    .intro_image {
    width: 220px !important;
    height: 220px !important;
  }
  .sub_wrap
    .sub_back
    .sub_main_area
    .vision_container
    .founded_img_box
    .organization_img {
    height: 65vh !important;
  }
  .sub_wrap {
    .sub_back {
      .navi_area {
        .navi_back {
          width: 100%;

          .navi_box {
            width: 140px;
            padding: 14px 10px;
          }
        }
      }

      .sub_main_area {
        .sub_main_back {
          .intro_top_box {
            .intro_box {
              &.left {
                width: 100%;
              }

              .blank_box {
                display: none;
              }

              .left_top_box {
                .intro_title {
                  padding-left: 10px;
                }
              }

              .left_bottom_box {
                padding-top: 50px;
              }
            }
          }

          .history_container {
            border-left: 3px solid $main-color;

            &::before {
              width: 10px;
              height: 10px;
              left: -6px;
              border: 2px solid $main-color;
            }

            &::after {
              width: 10px;
              height: 10px;
              left: -6px;
              border: 2px solid $main-color;
            }

            .history_contents_box {
              .history_contents_row {
                .year_contents_box {
                  .contents_row {
                    .contents_text {
                      width: 100%;
                    }

                    .sub_year {
                      display: block;
                      padding-bottom: 10px;
                    }
                  }
                }
              }
            }
          }

          .vision_container {
            .vision_contents_box {
              .first_box {
                .left_box {
                  display: none;
                }

                .right_box {
                  width: 100%;
                }
              }

              .top_box {
                width: 100%;
              }

              .vision_row {
                padding: 33px 10px;

                &.visible {
                  display: block;
                }

                &.first {
                  width: 100%;
                }

                &::after {
                  display: none;
                }

                &.second {
                  &::after {
                    display: none;
                  }
                }

                &.third {
                  &::after {
                    display: none;
                  }
                }
              }
            }
          }

          .map_container {
            .map_text_box {
              .text_box {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .board_wrap {
    .board_back {
      .board_container {
        .list_area {
          .list_box {
            .company_table {
              .company_row {
                width: calc((100% - 14px) / 2);
                margin-right: 7px;

                &:nth-child(3n) {
                  margin-right: 7px;
                }

                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }

            .img_table {
              .img_row {
                width: 100%;
                margin-right: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .sub_main_back {
    width: 80% !important;
  }
  .footer_area .footer_back .footer_top_box .footer-sub-wrap {
    width: 100%;
    text-align: center;
  }
  .footer_area .footer_back .footer_top_box .ftbox-1 {
    display: block;
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #869593;
    padding: 15px 0 15px 0;
  }

  .board_wrap
    .board_back
    .board_container
    .facility_box
    .facility_select_box
    .facility_list_box {
    .facility_item {
      width: calc((100% - 25px) / 2);
    }
    .facility_item:nth-child(2n) {
      margin-left: 25px !important;
    }
    .facility_item + .facility_item {
      margin-left: 0px;
    }
  }

  .myCalendar {
    .calendar_date {
      display: flex;
    }
  }

  .myCalendar .calendar_box .calendar_List .day.in-range::after {
    width: 45px;
    height: 45px;
  }
  .myCalendar .calendar_box .calendar_List .day.start-date::before,
  .myCalendar .calendar_box .calendar_List .day.end-date::before {
    width: 45px;
    height: 45px;
  }

  .myCalendar .calendar_box .calendar_List .day.today {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 1024px) {
  .search_wrap {
    background-color: #f8f8f8;
    min-height: 80vh;
    .search_back {
      .search_container {
        width: 80%;
        margin: 0 auto;
        .list_area .list_box {
          .board_body .body_row {
            .row_wrap {
              display: block;
              padding: 15px 5px;
              .row_title {
                font-size: 17px;
              }
              .row_date {
                padding-top: 15px;
              }
              .no_data {
                font-size: 17px;
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
  .sub_wrap .sub_back .fusionSupportProgram {
    .fusionSupportProgram_img,
    .chungnamInnovationProject_img,
    .chungnamInnovationCluster_img,
    .carbonNeutralInfrastructure_img,
    .localIndustryTalentDevelopment_img,
    .koreanQuickStartProgram_img,
    .smeResearchTalentSupport_img,
    .chungnamIndustryAcademiaProgram_img,
    .youthVentureProgram_img,
    .dangjinStartupSupport_img,
    .hopeReturnPackage_img,
    .hongseongIssueWarehouse_img {
      @include background-set(100%, 180vw, contain);
    }
  }
}

@media (max-width: 900px) {
  .sub-txt-kcl {
    font-size: 15px !important;
  }
  .carbon-floor-wrap {
    display: block !important;
  }
  .carbon-floor-left {
    display: block !important;
    width: 100%;
    padding: 10px !important;
    font-size: 27px !important;
  }
  .carbon-floor-right {
    display: block !important;
    width: 100%;
    font-size: 16px !important;
    margin-left: 0 !important;
  }
  .carbon-floor-wrap:not(:first-child) {
    padding-top: 15px !important;
  }
  .board_wrap .board_back .board_container .list_area .search_box select {
    display: block;
    width: 100%;
    margin-right: 0px !important;
    margin-bottom: 7px !important;
  }
  .board_wrap
    .board_back
    .board_container
    .list_area
    .search_box
    .search_input {
    display: inline-block;
    width: 88%;
  }
  .board_wrap .board_back .board_container .list_area .search_box .option-box {
    width: 12%;
  }
  .board_wrap
    .board_back
    .board_container
    .list_area
    .search_box
    .option-box
    .reset-btn {
    display: none;
  }
  .board_wrap
    .board_back
    .board_container
    .inquiry_area
    .inquiry_img_box
    .inquiry_img.inquiry_img_1,
  .inquiry_img_2,
  .inquiry_img_3,
  .inquiry_img_4,
  .inquiry_img_5 {
    height: 66vw !important;
  }
  .board_container-sub .inquiry_area .inquiry_top_box .top_info_box {
    display: block !important;
    width: 100% !important;

    &.right {
      margin-top: 30px;
    }
  }
  .board_container-sub .inquiry_area .inquiry_img_box .inquiry_img.bottom {
    height: 15vw !important;
  }
  .board_container-sub .inquiry_area .inquiry_img_box.bottom {
    padding: 50px 0 !important;
  }
  .board_container-sub
    .inquiry_area
    .inquiry_top_box
    .top_contents_box
    .inquiry_table_box {
    padding: 5px 0 97px !important;
  }
  .board_container-sub
    .inquiry_area
    .inquiry_top_box
    .top_contents_box
    .inquiry_table_box.bottom {
    padding: 5px 0 80px !important;
  }
  .board_container-sub
    .inquiry_area
    .inquiry_top_box
    .top_contents_box
    .inquiry_table_box
    .inquiry_table {
    height: 24vw !important;
  }
  .board_container-sub {
    width: 90% !important;
  }
  .sub_wrap .sub_back .sub_banner_area .sub_banner_back .navi_text {
    font-size: 11px !important;
  }
  .sub_wrap
    .sub_back
    .sub_main_area
    .vision_container
    .introduce_image_box
    .introduce_image
    iframe {
    height: 100% !important;
  }
  .sub_wrap
    .sub_back
    .sub_main_area
    .vision_container
    .introduce_image_box
    .introduce_image {
    height: 55vw !important;
  }
  .footer_area .footer_back .footer_top_box .ftbox-2,
  .footer_area .footer_back .footer_top_box .ftbox-3 {
    width: 50%;
  }
  .footer_area .footer_back .footer_bottom_box {
    width: 90%;
  }

  .board_wrap .board_back .board_container .facility_box .facility_first {
    flex-direction: column;
    .facility_left,
    .facility_right {
      width: 100%; /* 전체 너비를 차지 */
      margin-bottom: 20px; /* 요소 간의 간격 추가 */
    }
    .facility_right {
      margin-left: 0px;
    }
  }
  .board_wrap .board_back .board_container .facility_box .facility_second {
    flex-direction: column;
    .facility_left,
    .facility_right {
      width: 100%; /* 전체 너비를 차지 */
      margin-bottom: 20px; /* 요소 간의 간격 추가 */
    }
    .facility_right {
      margin-left: 0px;
      .facility_input {
        height: 400px;
      }
    }
  }
  .board_wrap
    .board_back
    .board_container
    .facility_box
    .facility_second
    .facility_right
    .facility_input
    .row_btn_box {
    height: 20%;
  }

  .myCalendar .calendar_box .calendar_List .day.in-range::after {
    width: 50px;
    height: 50px;
  }
  .myCalendar .calendar_box .calendar_List .day.start-date::before,
  .myCalendar .calendar_box .calendar_List .day.end-date::before {
    width: 50px;
    height: 50px;
  }

  .myCalendar .calendar_box .calendar_List .day.today {
    width: 50px;
    height: 50px;
  }

  .propulsion_box
    .propulsion_content_box
    .propulsion_content
    .propulsion_content_text_box
    .content_text {
    font-size: 24px;
  }
}

@media (max-width: 700px) {
  .board_wrap
    .board_back
    .board_container
    .facility_box
    .facility_select_box
    .facility_list_box {
    .facility_item {
      width: calc((100%));
    }
    .facility_item:nth-child(2n) {
      margin-left: 0px !important;
    }
    .facility_item + .facility_item {
      margin-left: 0px;
    }
  }
  .sub_wrap .sub_back .navi_area .navi_back .navi_box {
    &.business_field {
      width: 100%;
    }
    &.formation {
      width: 100%;
    }
    &.enterprise {
      width: 100%;
    }
    &.human {
      width: 100%;
    }
    &.founded {
      width: 100%;
    }
  }
}
@media (max-width: 600px) {
  .main_area
    .main_back
    .first_container
    .first_back
    .last_rows
    .contents_row
    .contents_title {
    font-size: 20px !important;
  }
  .footer_area .footer_back .footer_bottom_box {
    text-align: center;
  }

  .myCalendar .calendar_box .calendar_List .day.in-range::after {
    width: 50px;
    height: 50px;
  }
  .myCalendar .calendar_box .calendar_List .day.start-date::before,
  .myCalendar .calendar_box .calendar_List .day.end-date::before {
    width: 50px;
    height: 50px;
  }

  .myCalendar .calendar_box .calendar_List .day.today {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 400px) {
  .objective_box_project .object-txt.project {
    font-size: 16px !important;
  }
  .objective_box_project .object-txt.project span {
    font-size: 20px !important;
  }
  .project-result-table td {
    font-size: 0.85em !important;
  }
  .footer_area .footer_back .footer_top_box .ftbox-2 select,
  .footer_area .footer_back .footer_top_box .ftbox-3 select {
    font-size: 11px;
  }
  .board_wrap
    .board_back
    .board_container
    .list_area
    .search_box
    .option-box
    .search_btn {
    width: 30px !important;
    height: 22px !important;
  }

  .myCalendar .calendar_box .calendar_List .day.in-range::after {
    width: 35px;
    height: 35px;
  }
  .myCalendar .calendar_box .calendar_List .day.start-date::before,
  .myCalendar .calendar_box .calendar_List .day.end-date::before {
    width: 35px;
    height: 35px;
  }

  .myCalendar .calendar_box .calendar_List .day.today {
    width: 35px;
    height: 35px;
  }
  .search_wrap
    .search_back
    .search_container
    .list_area
    .list_box
    .board_body
    .body_row
    .row_wrap {
    .row_title {
      font-size: 16px;
    }
  }
}

/* 미디어 쿼리로 높이 조정 */
@media (max-width: 768px) {
  .inner_item_box.second {
    margin-top: 5px !important;
  }
  .result_inner_box.local2 {
    display: inline-block !important;
    margin: 0 auto !important;
    width: 100% !important;
  }
  .inner_item_box.first, .inner_item_box.second {
    width: 100% !important;
  }
  .result_content_box.local2 {
    margin: 0 auto !important;
    padding-top: 85px;
    width: 100% !important;
  }
  .content_item.first {
    margin-top: 15px;
  }
  .content_item.first, .content_item.second, .content_item_box {
    font-size: 18px !important;
    width: 100% !important;
  }
  .content_item_round {
    display: none;
  }
  .content_item_box {
    font-size: 18px !important;
    width: 100% !important;
  }
  .result_inner_box.local .content_item_text {
    margin-right: 0 !important;
    padding-top: 70px;
  }
  .result_inner_box.local {
    flex-direction: column !important;
  }
  .result_inner_box {
    width: 100%;
  }
  .result_inner_box.last {
    padding-top: 190px !important;
    flex-direction: column;
    margin-top: 130px !important;
    padding-bottom: 150px;
  }
  .result_content_box {
    margin: 10px 0 !important;
    flex-direction: column;
  }
  .result_content_item {
    font-size: 22px !important;
    height: 110px !important;
    width: 110px !important;
  }
  .result_title_box {
    font-size: 22px !important;
    height: 50px !important;
  }
  .objective_box {
    padding: 20px 0 60px;
  }
  .title_text {
    font-size: 19px !important;
  }
  .sub_main_back {
    padding-top: 20px !important;
  }
  .subtab-wrap div {
    display:none;
  }
  .subtab-wrap div:not(:last-child)::after {
    content: "────";
    color: #e3e3e3;
  }
  .project-result-table td span {
    padding-left: 0px !important;
  }
  .subtab-wrap {
    width: 95% !important;
  }
  .objective_box .objective_title.first {
    font-size: 22px !important;
  }
  .sub_main_back {
    width: 90% !important;
  }
  .carbon-work-wrap {
    display: block; /* 모바일에서는 블록 레벨 요소로 변경 */
    padding-bottom: 15px;
  }

  .carbon-content-left,
  .carbon-content-right {
    width: 100%; /* 모바일에서는 전체 폭을 사용하도록 설정 */
    display: block;
  }

  .carbon-content-left {
    border-radius: 10px 10px 0 0;
  }

  .carbon-content-right {
    border-radius: 0 0 10px 10px; /* 모바일에서는 하단 모서리만 둥글게 설정 */
    border-top: none; /* 모바일에서는 상단 경계선 제거 */
    padding: 9px 5px !important;
  }

  .carbon-work-wrap:not(:first-child) {
    padding-top: 0; /* 모바일에서는 간격 제거 */
  }
  .cluster-txt {
    font-size: 18px !important;
  }
  .objective_box_project .object-txt.project {
    font-size: 19px;
  }
  .objective_box_project .object-txt.project span {
    font-size: 24px;
  }
  .result_content_box {
    display: flex;
    flex-direction: column;
  }
  .result_content_title {
    width: 100% !important;
    height: 45px !important;
    margin-bottom: 20px;
    font-size: 18px !important;
  }
  .final_result_box .result_content_box .result_content_text_box {
    padding-left: 0 !important;
  }
  .content_text {
    font-size: 17px !important;
  }

  .propulsion_content_title {
    font-size: 23px !important;
    height: 0 !important;
    padding: 25px 50px !important;
    width: 0 !important;
  }

  .final_result_box,
  .objective_box_project {
    padding: 20px 0 60px !important;
  }

  .project-result-table-local td:before {
    align-items: center;
    background-color: #f4f4f4;
    border-right: 1px solid #ddd;
    box-sizing: border-box;
    color: #707070;
    content: attr(data-label);
    display: flex;
    font-weight: 700;
    height: 100%;
    left: 0;
    overflow: hidden;
    padding-left: 10px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
    width: 33%;
  }
}

@media (max-width: 480px) {
  .carbon-content-left,
  .carbon-content-right {
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  .propulsion_box
    .propulsion_div_content_box
    .propulsion_div_content
    .content_middle
    .content_text,
  .propulsion_box
    .propulsion_div_content_box
    .propulsion_div_content
    .content_bottom
    .content_text {
    font-size: 16px !important;
  }

  .objective_box .objective_content_box {
    &.objective_content_mobile {
      .objective_img_content_box {
        width: 70% !important;
        margin-right: 15% !important;
      }
    }
  }
}

@media (max-width: 1000px) {
  .final_result_head {
    font-size: 18px !important;
  }
  .academia_objective_item:last-child {
    margin-bottom: 0 !important;
  }
  .academia_objective_item {
    font-size: 19px !important;
  }
  .academia_objective_content_left, .academia_objective_content_right {
    width: 100% !important;
  }
  .objective_content_box.academia {
    flex-direction: column;
  }
  .academia-floor-item-head {
    padding: 5px !important;
  }
  .academia-floor-item-footer p {
    font-size: 20px !important;
    padding: 5px 0 !important;
  }
  .academia-floor-item-body {
    font-size: 20px !important;
    padding-top: 5px !important;
  }
  .content_icon.none {
    display: block !important;
  }
  .icon_arrow_2, .icon_arrow_4 {
    display: inline-block;
    transform: rotate(90deg);
    transform-origin: center;
  }
  .icon_arrow_4 {
    margin: 5px 0;
  }
  .academia-floor-item {
    width: 100% !important;
  }
  .academia-floor-wrap {
    flex-direction: column;
    height: 100% !important;
    margin-bottom: 0 !important;
  }
  .inner_item_box.division {
    width: 100% !important;
  }
  .business_inner_logo_box {
    .logo_content {
      &.hongseong {
        justify-content: center;
        margin-right: 0px;
      }
      &.main {
        margin-right: 0px;
      }
    }
  }
  .objective_box .objective_content_box {
    &.objective_content_mobile {
      flex-direction: column;
      align-items: center;
      .objective_img_content_box {
        margin-right: 10%;
        height: 100%;
      }
      .content_icon {
        padding: 30px;
        display: inline-block;
        transform: rotate(90deg);
        transform-origin: center;
      }
      .objective_text_content_box {
        display: block;
        width: 80%;
      }
    }
  }
}
@media (max-width: 700px) {
  .business_inner_logo_box {
    .logo_content {
      &.hongseong {
        justify-content: center;
        margin-right: 0px;
        width: 70%;
      }
      &.main {
        justify-content: center;
        margin-left: 0px;
      }
      .logo_hongseong {
      }
      .logo_main {
      }
    }
  }
}

@media (max-width: 768px) {
  .final_result_item1 {
    margin-right: 0px !important;
  }
  .final_result_item2, .final_result_item3 {
    margin-top: 10px !important;
  }
  .final_result_body .item_body .item_txt {
    font-size: 19px !important;
  }
  .final_result_body .item_head {
    font-size: 22px !important;
    height: 100% !important;
    padding: 7px !important;
  }
  .final_result_body {
    display: inline-block;
    width: 100% !important;
  }
  .inner_item_box {
    margin-right: 0 !important;
  }
  .propulsion_box .propulsion_div_content_box {
    display: block;
    .propulsion_div_content {
      // margin-right: 50px;
      flex-direction: row;
      &.first {
        width: 100%;
        font-size: 22px;
      }
      &.second {
        width: 100%;
      }
      &.third {
        width: 100%;
      }
      &.icon {
        width: 100%;
        margin: 5px 0;
      }
      .content_top {
        margin-bottom: 0px;
        margin-right: 10px;
        height: 200px;
        font-size: 22px;
      }
      .content_bottom {
        margin-bottom: 0px;
      }
      .content_middle {
        margin-right: 10px;
        margin-bottom: 0px;
      }
    }
  }
  .propulsion_box
    .propulsion_div_content_box
    .propulsion_div_content
    .content_middle_icon,
  .propulsion_box .propulsion_div_content_box .propulsion_div_content {
    .content_top_icon {
      margin-bottom: 0px;
    }
    .content_middle_icon,
    .content_bottom_icon {
      display: inline-block;
      transform: rotate(90deg);
      transform-origin: center;
      height: 50px;
      margin-bottom: 0px;
      padding: 5px;
      &.button_none {
        display: none;
      }
    }
  }
  .objective_box .objective_content_box {
    &.objective_content_mobile {
      .objective_img_content_box {
        width: 60%;
      }
    }
    &.hope {
      flex-direction: column;
      .objective_content.hope {
        width: 100% !important;
        .content_text_box.hope {
          padding: 10px 0 20px !important;
        }
      }
    }
  }
  .content_text.hope,
  .hope-txt1,
  .hope-txt2,
  .hope-txt3 {
    font-size: 22px !important;
  }
  .objective_box .objective_content_box .objective_content .content_title {
    height: 45px !important;
  }
  .objective_box .objective_title.first {
    font-size: 17px !important;
  }

  .hope-last-txt1 {
    font-size: 23px !important;
  }

  .hope-last-txt2 {
    font-size: 30px !important;
  }
}

@media (max-width: 480px) {
  .content_text.fusion, .content_text.hongseong_txt, .content_text.korea {
    padding-left: 20px !important;
  }
  .content_title.korea {
    font-size: 18px !important;
  }
  .content_text.korea {
    padding-left: 20px !important;
  }
  .korea-txt {
    font-size: 18px !important;
  }
  .content_text.fusion {
    padding-left: 20px !important;
  }
  .propulsion_box
    .propulsion_div_content_box
    .propulsion_div_content
    .content_top {
    font-size: 18px !important;
  }
  .propulsion_box
    .propulsion_div_content_box
    .propulsion_div_content
    .content_middle
    .content_text,
  .propulsion_box
    .propulsion_div_content_box
    .propulsion_div_content
    .content_bottom
    .content_text {
    font-size: 14px !important;
  }
  .objective_box .objective_content_box {
    &.objective_content_mobile {
      .objective_img_content_box {
        width: 80% !important;
        margin-right: 20% !important;
      }
    }
  }

  .final_result_box .table-container {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
  .business_content_text_box {
    padding-left: 0 !important;
    padding-top: 20px;
  }
  .business_content {
    flex-direction: column;
  }
  .content_text {
    padding-left: 0 !important;
  }
  .business_content_title {
    font-size: 23px !important;
  }
  .business_content:last-child {
    padding-top: 30px;
  }
}
