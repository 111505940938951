// 전역 변수
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Pretendard_m";
  word-break: keep-all;
}


html,
body {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  word-break: keep-all;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.Screen {
  width: 100%;
  height: 100%;
  position: relative;
}

#root {
  width: 100%;
  height: 100%;
}

input,
select {
  outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// 이미지

@mixin background_set($width, $height, $size) {
  background-position: center;
  background-repeat: no-repeat;
  background-size: $size;
  width: $width;
  height: $height;
}

// 버튼 패딩 기본 9px 20px
@mixin default_button_set($padding) {
  display: inline-block;
  padding: $padding;
  border-radius: 5px;
  color: $white;
  background-color: $main-color;
  cursor: pointer;
  box-shadow: 4px 4px 4px -4px $gray;
}

@mixin delete_button_set($padding) {
  display: inline-block;
  padding: $padding;
  border-radius: 5px;
  color: $white;
  background-color: $delete-color;
  cursor: pointer;
  box-shadow: 4px 4px 4px -4px $gray;
}

@mixin sub_button_set($padding) {
  display: inline-block;
  padding: $padding;
  border-radius: 5px;
  color: $white;
  background-color: $sub-color;
  cursor: pointer;
  box-shadow: 4px 4px 4px -4px $gray;
}

@mixin gray_button_set($padding) {
  display: inline-block;
  padding: $padding;
  border-radius: 5px;
  color: $white;
  background-color: $gray;
  cursor: pointer;
  box-shadow: 4px 4px 4px -4px $gray;
}

// 색상
$black: #000000;
$mid_black: #414141;
$white: #ffffff;
$deep-gray: #464646;
$gray: #d6d6d6;
$light-gray: #707070;
$back-gray: #dbdbdb;
$line-gray: #e8e8e8;

$main-color: #4FAAA1;
$sub-color: #496965;
$shadow-color: #b1472d69;
$box-icon-color: #fd9f9f;
$shadow-color: #0000004d;
$shadow-color-input: #00000010;
$shadow-color-box: #00000029;
$back-color: #f8f3f2;
$input-color: #f2e5e2;
$delete-color: #fa3737cc;
$modal-color: #9999999f;

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.w100 {
  width: 100%;
}