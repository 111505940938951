@import "hoseoUtil.scss";

.screen {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
}

.empty_wrap {
  position: relative;
  height: calc(100vh - 461px);
  background-color: #f8f8f8;

  .empty_back {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    border: 1px solid #c9c9c9;
    border-radius: 20px;
    background-color: #fff;

    .empty_text {
      font-size: 20px;
      padding-top: 30px;
      width: 100%;
      text-align: center;

      span {
        font-family: "Pretendard_b";
        color: $main-color;
      }
    }

    .empty_logo {
      @include background-set(50px, 50px, contain);
      background-image: url("../image/color_logo_2.png");
      margin: 0 auto;
    }
  }
}

.login_wrap {
  position: relative;
  height: calc(100vh - 461px);
  background-color: #f8f8f8;

  .login_back {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    border: 1px solid #c9c9c9;
    border-radius: 20px;
    background-color: #fff;

    .login_title {
      font-size: 30px;
      font-family: "Pretendard_b";
      text-align: center;
      padding-bottom: 30px;
    }

    .login_row {
      padding-bottom: 20px;

      .input_title {
        display: inline-block;
        vertical-align: middle;
        font-size: 20px;
        color: #555555;
        width: 100px;
      }

      .login_input {
        display: inline-block;
        vertical-align: middle;
        font-size: 20px;
        background-color: inherit;

        &::placeholder {
          color: #d6d6d6;
        }
      }
    }

    .submit_btn {
      width: 100%;
      margin-top: 20px;
      padding: 10px 0;
      background-color: $main-color;
      border-radius: 5px;
      color: #fff;
      font-size: 15px;
      cursor: pointer;
    }

    .error_text {
      padding-top: 10px;
      text-align: center;
      color: red;
    }
  }
}

.header_area {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;

  .header_wrap {
    //box-shadow: 0px 3px 6px #00000029;

    .header_back {
      width: 80%;
      margin: 0 auto;
    }

    .header_top_box {
      background-color: $main-color;
      width: 100%;
      height: 15px;

      .top_small_menu {
        color: #fff;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        padding-right: 20px;

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .header_bottom_box {
      padding: 30px 0;
      background-color: #fff;
      //border-bottom: 1px solid #c6c6c6;
      //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .logo_box {
        display: inline-block;
        vertical-align: middle;
        width: 260px;
        cursor: pointer;

        .logo_img {
          @include background-set(100%, 70px, contain);
          background-image: url("../image/header_logo.png");
        }
      }

      .right_box {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 260px);
        text-align: right;

        .menu_box {
          display: inline-block;
          vertical-align: middle;
          padding-right: 30px;
          text-align: right;
          width: calc(90% - 76px);

          .main_menu {
            cursor: pointer;
            position: relative;
            display: inline-block;
            vertical-align: middle;
            // color: #707070;
            color: black;
            font-size: 21px;
            font-weight: bold;
            width: calc(100% / 5);
            text-align: center;
            // cursor: pointer;
            letter-spacing: 1.1px;
            .menu_row {
              display: none;
              animation: growDown 500ms ease-in-out forwards;
              position: absolute;
              // vertical-align: top;
              text-align: center;
              color: #fff;
              z-index: 10;
              padding-top: 80px;
              top: 100%; // 부모 요소인 main_menu의 바로 아래에 위치
              left: 0;
              width: 100%;
              .sub_menu_box {
                position: absolute;
                width: 100%;
                .sub_menu {
                  cursor: pointer;
                  font-weight: normal;
                  font-size: 16px;
                  padding-bottom: 15px;
                  color: black;

                  &:last-child {
                    padding-bottom: 0;
                  }
                }
              }
              &.active {
                display: block;
              }
            }
          }
        }

        .icon_box {
          display: inline-block;
          vertical-align: middle;
          padding-left: 30px;
          border-left: 1px solid #d0d0d0;

          .menu_icon {
            display: inline-block;
            vertical-align: middle;
            @include background-set(40px, 40px, contain);
            background-image: url("../image/header_icon.png");
            cursor: pointer;

            &.list {
              background-image: url("../image/header_icon_list.png");
            }

            &.mobile {
              display: none;
            }
          }
          .search_icon_box {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            .search_icon {
              cursor: pointer;
              // position: relative;
              @include background-set(40px, 40px, contain);
              background-image: url("../image/header_search_icon.png");
              margin-left: 20px;
              &.active {
                background-image: url("../image/header_search_cancel_icon.png");
              }
            }
            .search_form {
              z-index: 10;
              position: absolute;
              right: 0; /* 오른쪽에서 5% 떨어진 위치 */
              top: 10%; /* 위쪽에서 10% 떨어진 위치 */
              transform: translate(0, 80%);
              box-shadow: 0px 3px 6px #00000029;
              border: 1px solid #4faaa1;
              border-radius: 10px;
              width: 300px;
              height: 60px;
              display: flex; /* Flexbox로 내부 요소 정렬 */
              align-items: center;
              background-color: #ffffff;
              padding: 0 10px 0 20px;
              input {
                font-size: 15px;
                width: 100%; /* input의 너비를 조정 */
                border: none; /* input의 기본 테두리 제거 */
                outline: none; /* input 클릭 시 아웃라인 제거 */
              }
              .search_submit {
                width: 30px; /* 아이콘 크기 */
                height: 30px; /* 아이콘 크기 */
                background-image: url("../image/header_search_inner_icon.png");
                background-size: contain; /* 아이콘 크기 조정 */
                background-repeat: no-repeat;
                background-position: center;
                cursor: pointer; /* 클릭 가능한 손 모양 커서 */
                border-radius: 0 10px 10px 0; /* 우측 둥근 모서리 */
                margin-left: 10px; /* 아이콘과 폼 사이의 간격 */
              }
            }
          }
        }
      }
    }

    .menu_show_area {
      display: none;

      &.active {
        display: block;
      }
    }

    .menu_show_box {
      display: none;
      animation: growDown 500ms ease-in-out forwards;
      transform-origin: top center;

      &.mobile {
        display: block;
        background-color: $main-color;
        position: absolute;
        z-index: 9;
        height: 100vh;
        width: 100%;
        overflow-y: scroll;
        padding-bottom: 50px;
      }

      &.web {
        display: block;
        background-color: #fff;
        position: absolute;
        z-index: 9;
        height: 315px;
        width: 100%;
      }

      .show_menu_back {
        &.mobile {
          padding: 45px;

          .menu_container {
            display: inline-block;
            width: 50%;
            vertical-align: top;
            color: #fff;

            .menu_row {
              padding-bottom: 50px;
              min-height: 325px;

              .main_menu {
                font-size: 20px;
                font-family: "Pretendard_bb";
                padding-bottom: 16px;
              }

              .main_menu.sla {
                margin-top: -60px;
              }
              .sub_menu_box {
                .sub_menu {
                  font-size: 13px;
                  padding-bottom: 16px;
                  cursor: pointer;

                  &:last-child {
                    padding-bottom: 0;
                  }
                }
              }
            }
          }
        }

        &.web {
          width: 80%;
          text-align: right;
          margin: 0 auto;

          .menu_container {
            width: calc(100% - 260px);
            display: inline-block;
            padding-right: 30px;
            padding-top: 26px;
            .menu_box {
              display: inline-block;
              vertical-align: middle;
              text-align: right;
              width: calc(90% - 76px);
              .menu_row {
                display: inline-block;
                vertical-align: top;
                text-align: center;
                color: #fff;
                width: calc(100% / 5);

                .sub_menu_box {
                  .sub_menu {
                    cursor: pointer;
                    font-size: 16px;
                    padding-bottom: 15px;
                    color: black;

                    &:last-child {
                      padding-bottom: 0;
                    }
                  }
                }
              }
            }
          }

          .blank_box {
            width: 76px;
            display: inline-block;
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  // .header_area .header_wrap .menu_show_box .show_menu_back.web .menu_container {
  //   width: calc(100% - 220px);
  //   display: inline-block;
  //   padding-right: 30px;
  //   padding-top: 26px;
  // }
}

.footer_area {
  // position: absolute;
  // bottom: 0;
  width: 100%;
  background-color: $sub-color;

  .footer_back {
    // text-align: center;

    .footer_top_box {
      border-bottom: 1px solid #869593;
      border-top: 1px solid #869593;
      width: 100%;

      .footer-sub-wrap {
        width: 70%;
        margin: 0 auto;
      }
      .ftbox-1 {
        display: inline-block;
        width: 52%;
        .footer_icon {
          @include background-set(28px, 20px, contain);
          display: inline-block;
          vertical-align: middle;
          margin-right: 35px;

          &:last-child {
            margin-right: 0;
          }

          &.youtube {
            background-image: url("../image/icon_youtube.png");
            cursor: pointer;
          }

          &.youtube-2 {
            background-image: url("../image/icon_youtube2.png");
            cursor: pointer;
          }

          &.insta {
            width: 26px;
            height: 26px;
            background-image: url("../image/icon_insta.png");
            cursor: pointer;
          }

          &.naver {
            width: 26px;
            height: 26px;
            background-image: url("../image/icon_naver.png");
            cursor: pointer;
          }

          &.mail {
            width: 26px;
            height: 26px;
            background-image: url("../image/icon_mail.png");
            cursor: pointer;
          }
        }
      }
      .ftbox-2,
      .ftbox-3 {
        display: inline-block;
        width: 24%;

        select {
          width: 100%; /* 셀렉트 박스를 부모 요소의 너비에 꽉 차게 설정 */
          height: 55px; /* 적절한 높이 설정 */
          padding: 10px; /* 패딩 설정 */
          border: 1px solid #869593; /* 테두리 설정 */
          background-color: #496965; /* 배경색 설정 */
          color: #fff;
          -webkit-appearance: none; /* 기본 스타일링 제거 */

          /* 화살표 모양 변경 */
          background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path d="M7 10l5 5 5-5z" fill="%23ffffff"/></svg>');
          background-repeat: no-repeat;
          background-position: right 10px center;
          background-size: 22px 22px; /* 화살표 아이콘 크기 설정 */

          /* IE11 이하에서 기본 화살표 숨기기 */
          &::-ms-expand {
            display: none;
          }
        }
      }
      .full-width-select {
        width: 100%;
      }
    }

    .footer_bottom_box {
      padding: 34px 0 46px;
      color: #fff;
      width: 70%;
      margin: 0 auto;

      .bottom_first_info {
        padding-bottom: 34px;

        .line_text {
          font-size: 20px;
          display: inline-block;
          vertical-align: middle;
          border-right: 2px solid #fff;
          padding: 0 10px;
          cursor: pointer;

          &:first-child {
            padding-right: 10px;
            padding-left: 0;
          }

          &:last-child {
            border-right: none;
          }
        }
      }

      .bottom_second_info {
        font-size: 16px;
        line-height: 30px;
        padding-bottom: 30px;

        p {
          display: inline-block;
          vertical-align: middle;
          padding-left: 20px;

          &:nth-of-type(5) {
            padding-left: 0;
          }

          &:first-child {
            padding-left: 0;
          }
        }
      }

      .bottom_copy {
        font-size: 16px;
      }
    }
  }
}

.header_area .header_wrap .menu_show_box.web {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 설정 */
}

.main_area {
  height: auto;
  min-height: 100%;
  position: relative;
  padding-top: 131px;

  .main_back {
    .scroll_box {
      position: fixed;
      right: 150px;
      bottom: 50px;
      z-index: 2;

      .scroll_icon {
        cursor: pointer;
        @include background-set(70px, 70px, contain);
        background-image: url("../image/scroll_icon.png");
      }
    }

    .main_banner_box {
      height: 35.25vw;
      width: 100%;

      .slide_wrap {
        width: 100%;
        height: 100%;

        .swiper {
          width: 100%;
          height: 100%;
        }

        .swiper_slide {
          width: 100%;
          height: 100%;

          .slide_image_box {
            @include background-set(100%, 100%, contain);
            background-image: url("../image/hoseo_banner.jpeg");
            background-position: center;
            position: relative;

            &::before {
              position: absolute;
              content: "";
              width: 100%;
              height: 100%;
              // background: linear-gradient(
              //   to right,
              //   rgba(255, 255, 255, 1) 0%,
              //   rgba(255, 255, 255, 0) 50%,
              //   rgba(255, 255, 255, 0) 100%
              // );
            }

            .slide_text_box {
              position: absolute;
              bottom: 50px;
              left: 7%;

              .banner_logo_box {
                display: inline-block;
                vertical-align: middle;
                padding-right: 35px;

                .banner_logo {
                  @include background-set(175px, 175px, contain);
                  background-image: url("../image/banner_logo.png");
                }
              }

              .banner_text {
                display: inline-block;
                vertical-align: middle;
                padding-left: 35px;
                color: #fff;
                font-size: 30px;
                line-height: 50px;
                width: 535px;
                border-left: 2px solid #fff;
              }
            }
          }
        }
      }
    }

    .home_menu_container {
      background-color: $sub-color;
      border-bottom: 15px solid $main-color;
      padding: 48px 0;

      .home_menu_box {
        width: 50%;
        margin: 0 auto;
        text-align: center;

        .home_menu_row {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% / 6);

          .home_menu_icon {
            cursor: pointer;
            display: inline-block;
            @include background-set(80px, 125px, contain);
            background-image: url("../image/menu_1.png");

            &.second {
              background-image: url("../image/menu_2.png");
            }

            &.third {
              background-image: url("../image/menu_3.png");
            }

            &.fourth {
              background-image: url("../image/menu_4.png");
            }

            &.fifth {
              background-image: url("../image/menu_5.png");
            }

            &.sixth {
              width: 104px;
              background-image: url("../image/home_menu_7.png");
            }
          }
        }
      }
    }

    .first_container {
      padding: 90px 0;

      .first_back {
        width: 70%;
        margin: 0 auto;

        .first_row {
          display: inline-block;
          vertical-align: middle;
          width: calc((100% - 40px) / 2);
          box-shadow: 3px 3px 10px #00000039;
          background-color: #fff;
          border-radius: 5px;
          padding: 35px 45px;
          min-height: 663px;

          &.news {
            display: inline-block;
            vertical-align: middle;
            width: 100% !important;
            box-shadow: 3px 3px 10px #00000039;
            background-color: #fff;
            border-radius: 5px;
            padding: 35px 45px;
            margin-right: 45px;
          }

          &:last-child {
            margin-left: 40px;
          }

          .row_top_box {
            padding-bottom: 35px;

            .row_title {
              display: inline-block;
              vertical-align: middle;
              color: #121212;
              font-size: 25px;
              width: calc(100% - 100px);
              font-family: "Pretendard_b";
            }

            .row_btn_box {
              display: inline-block;
              vertical-align: middle;
              text-align: right;

              .more_btn {
                background-color: $main-color;
                border-radius: 5px;
                font-size: 20px;
                color: #fff;
                padding: 5px 15px;
                cursor: pointer;
              }
            }
          }

          .contents_row {
            border-bottom: 1px solid #d8d8d8;
            padding: 20px 0 16px;
            cursor: pointer;

            &:last-child {
              border-bottom: none;
            }

            .contents_title {
              font-size: 25px;
              font-family: "Pretendard_b";
              padding-bottom: 18px;
              color: #121212;
              height: 100px;

              .new_btn {
                display: inline-block;
                vertical-align: text-top;
                margin-left: 10px;
                border-radius: 20px;
                border: 1px solid $main-color;
                color: $main-color;
                font-size: 16px;
                font-family: "Pretendard_m";
                padding: 3px 11px;
              }
            }

            .contents_text {
              font-size: 20px;
              color: #121212;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              height: 50px;
            }

            .contents_date {
              color: $main-color;
              font-size: 20px;
              padding-top: 13px;
            }
          }
        }

        .last_rows {
          display: inline-block;
          vertical-align: middle;
          width: calc((100% - 40px) / 2);
          box-shadow: 3px 3px 10px #00000039;
          background-color: #fff;
          border-radius: 5px;
          padding: 35px 45px;
          min-height: 663px;

          &.news {
            display: inline-block;
            vertical-align: middle;
            width: 100% !important;
            box-shadow: 3px 3px 10px #00000039;
            background-color: #fff;
            border-radius: 5px;
            padding: 35px 45px;
          }

          .row_top_box {
            padding-bottom: 35px;

            .row_title {
              display: inline-block;
              vertical-align: middle;
              color: #121212;
              font-size: 25px;
              width: calc(100% - 100px);
              font-family: "Pretendard_b";
            }

            .row_btn_box {
              display: inline-block;
              vertical-align: middle;
              text-align: right;

              .more_btn {
                background-color: $main-color;
                border-radius: 5px;
                font-size: 20px;
                color: #fff;
                padding: 5px 15px;
                cursor: pointer;
              }
            }
          }

          .contents_row {
            border-bottom: 1px solid #d8d8d8;
            padding: 20px 0 16px;
            cursor: pointer;

            &:last-child {
              border-bottom: none;
            }

            .contents_title {
              font-size: 25px;
              font-family: "Pretendard_b";
              padding-bottom: 15px;
              overflow: hidden;
              //white-space: nowrap;
              text-overflow: ellipsis;
              color: #121212;

              .new_btn {
                display: inline-block;
                vertical-align: text-top;
                margin-left: 10px;
                border-radius: 20px;
                border: 1px solid $main-color;
                color: $main-color;
                font-size: 16px;
                font-family: "Pretendard_m";
                padding: 3px 11px;
              }
            }

            .contents_text {
              font-size: 20px;
              color: #121212;
              overflow: hidden;
              //white-space: nowrap;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              height: 50px;
            }

            .contents_date {
              color: $main-color;
              font-size: 15px;
              padding-top: 13px;
            }
          }
        }
      }
    }

    .middle_banner_box {
      position: relative;
      @include background-set(100%, 288px, cover);
      background-image: url("../image/middle_banner.png");

      &.second {
        background-image: url("../image/middle_banner_2.png");
      }

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backdrop-filter: blur(3px);
        background: linear-gradient(
          rgba(0, 0, 0, 0.4),
          rgba(0, 0, 0, 0.6)
        ); /* 조금 어두운 검은색 그라데이션 설정 */
      }

      .banner_text_box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #fff;

        .banner_title {
          font-size: 30px;
          padding-bottom: 16px;
          font-family: "Pretendard_b";
        }

        .banner_sub_text {
          font-size: 25px;
          padding-bottom: 53px;
        }

        .more_btn {
          font-size: 20px;
          cursor: pointer;
        }
      }
    }

    .second_container {
      padding: 95px 0;

      .second_back {
        width: 70%;
        margin: 0 auto;

        .second_row {
          display: inline-block;
          vertical-align: middle;
          width: calc((100% - 120px) / 4);
          margin-right: 40px;
          box-shadow: 3px 3px 10px #00000039;
          border-radius: 5px;

          &:last-child {
            margin-right: 0;
          }

          .row_image {
            @include background-set(100%, 210px, cover);
            /* background-size: 50% auto; */
            background-image: url("../image/no_image.png");
            border-radius: 5px 5px 0 0;
            border-bottom: 1px solid #e0e0e0;
            box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1); /* 아래쪽에만 그림자 효과 */
            background-position: "top center" !important;
          }

          .row_bottom_box {
            position: relative;
            padding: 30px;
            height: 320px;

            .row_title {
              font-family: "Pretendard_b";
              font-size: 25px;
              padding-bottom: 15px;
            }

            .row_text {
              font-size: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              height: 50px;
            }

            .more_btn {
              position: absolute;
              bottom: 20px;
              margin-top: 15px;
              background-color: $main-color;
              color: #fff;
              display: inline-block;
              padding: 5px 10px;
              border-radius: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .third_container {
      background-color: #fff;
      padding: 100px 0 250px;

      .third_back {
        width: 80%;
        margin: 0 auto;

        .third_top_box {
          text-align: center;
          padding-bottom: 75px;

          .third_title {
            font-size: 30px;
            font-family: "Pretendard_b";
            padding-bottom: 15px;
          }

          .more_btn {
            font-size: 20px;
            cursor: pointer;
          }
        }

        .third_contents_box {
          .third_row {
            box-shadow: 3px 3px 10px #00000039;
            background-color: #fff;
            border-radius: 5px;
            display: inline-block;
            vertical-align: middle;
            width: calc((100% - 39px) / 4);
            margin-right: 13px;

            &:last-child {
              margin-right: 0;
            }

            .title_box {
              background-color: $main-color;
              color: #fff;
              padding: 18px 0;
              text-align: center;
              font-size: 25px;
              font-family: "Pretendard_b";
              border-radius: 5px 5px 0 0;
            }

            .contents_box {
              padding: 30px;
              position: relative;

              &::after {
                position: absolute;
                content: "";
                @include background-set(47px, 49px, contain);
                background-image: url("../image/color_logo.png");
                top: 30px;
                right: 30px;
              }

              .contents_text {
                font-size: 20px;
                padding-bottom: 10px;

                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.alal-box {
  width: 100%;
  max-width: 1000px; /* 최대 너비 설정 */
  margin: 0 auto; /* 중앙 정렬 */
  overflow: hidden; /* 내부 요소가 넘치는 경우를 처리하기 위해 필요할 수 있습니다 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.alal {
  width: 100%; /* alal-box에 맞춰진 너비 */
  height: 0;
  padding-top: 100%; /* 이미지 비율을 유지하기 위한 특정 값 (16:9 비율) */
  background-image: url("../image/test.png"); /* 이미지 경로 설정 */
  background-size: contain; /* 이미지를 요소에 맞게 자동으로 크기 조절 */
  background-position: center; /* 이미지를 화면 정중앙에 위치 */
  background-repeat: no-repeat; /* 배경 이미지 반복 없음 */
}

.sub_wrap {
  background-color: #f8f8f8;

  .sub_back {
    .sub_banner_area {
      @include background-set(100%, 187px, cover);
      background-image: url("../image/middle_banner.png");
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #000000a3 70%, #000000d1 100%);
      }

      .sub_banner_back {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 60%;
        padding: 35px 0;

        .navi_text {
          color: #fff;
          font-size: 15px;
          padding-bottom: 23px;

          .home_icon {
            @include background-set(16px, 14px, contain);
            background-image: url("../image/home_icon.png");
            display: inline-block;
            vertical-align: text-top;
            margin-right: 7px;
          }
          .home_icon_sub {
            @include background-set(16px, 14px, contain);
            background-image: url("../image/home_icon_sub.png");
            display: inline-block;
            vertical-align: text-top;
            margin-right: 7px;
          }
        }

        .banner_main_text {
          color: #fff;
          text-align: center;
          font-size: 30px;
          font-family: "Pretendard_b";
        }
      }
    }

    .navi_area {
      background-color: $main-color;

      .navi_back {
        width: 60%;
        margin: 0 auto;

        .navi_box {
          display: inline-block;
          vertical-align: middle;
          color: #fff;
          width: 190px;
          border-left: 1px solid #ffffff6c;
          border-right: 1px solid #ffffff6c;
          font-size: 15px;
          padding: 14px 20px;
          cursor: pointer;
          position: relative;

          &.formation {
            width: 400px;
          }
          &.enterprise {
            width: 230px;
          }
          &.human {
            width: 330px;
          }
          &.founded {
            width: 220px;
          }
          .navi_select_box {
            display: none;
            position: absolute;
            background-color: $main-color;
            width: 100%;
            left: 0;
            top: 46px;
            padding: 14px 20px;
            animation: growDown 500ms ease-in-out forwards;
            transform-origin: top center;
            z-index: 2;

            &.active {
              display: block;
            }

            .select_row {
              padding: 0 0 10px;
              cursor: pointer;

              &:last-child {
                padding-bottom: 0;
              }
            }
          }

          .navi_main_text {
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 6px);
          }

          .navi_arrow {
            width: 6px;
            height: 6px;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            display: inline-block;
            vertical-align: middle;
            transform: rotate(135deg);
          }
        }
      }
    }

    .sub_main_area {
      background-color: #f8f8f8;

      &.founded {
        background-color: #f8f8f8;
        padding: 135px 0 150px;
      }

      &.intro {
        padding: 135px 0 150px;
      }

      &.history {
        padding: 135px 0;
      }

      &.vision {
        padding: 135px 0;
      }

      &.education {
        padding: 20px 0;
      }

      // &.temporary {
      //   padding: 70px 0;
      // }

      .sub_main_back {
        width: 50%;
        margin: 0 auto;
        &.image_box {
          width: 100%;
        }
      }

      .intro_top_box {
        // padding-bottom: 30px;

        .intro_box {
          vertical-align: top;
          display: inline-block;

          &.left {
            width: calc(100% - 240px);
          }

          &.right {
            width: 240px;
          }

          .blank_box {
            background-color: #f0f0f0;
            border-radius: 50%;
            width: 100%;
            height: 238px;
          }

          .left_top_box {
            .logo_box {
              display: inline-block;
              vertical-align: middle;

              .intro_logo {
                @include background-set(105px, 105px, contain);
                background-image: url("../image/color_logo_2.png");
              }
            }

            .intro_title {
              display: inline-block;
              vertical-align: middle;
              padding-left: 10px;
              color: $sub-color;
              font-size: 35px;
              font-family: "Pretendard_b";

              span {
                color: #d87f38;
              }
            }
          }

          .left_bottom_box {
            padding-top: 85px;

            .color_box {
              border-radius: 50%;
              width: 15px;
              height: 15px;
              background-color: #4aa79d;
              margin-bottom: 19px;

              &:nth-child(2) {
                background-color: #d87f38;
              }

              &:nth-child(3) {
                background-color: #aac587;
                margin-bottom: 0;
              }
            }
          }
          .right_top_box {
            .image_box {
              display: inline-block;
              vertical-align: middle;

              .intro_image {
                @include background-set(200px, 200px, cover);
                background-image: url("../image/intro_image1.png");
                border-radius: 50%;
                background-position: center; /* 이미지 위치 설정 */
                overflow: hidden;
                width: 220px;
                height: 220px;
              }
            }

            .intro_title {
              display: inline-block;
              vertical-align: middle;
              padding-left: 10px;
              color: $sub-color;
              font-size: 35px;
              font-family: "Pretendard_b";

              span {
                color: #d87f38;
              }
            }
          }
        }
      }

      .intro_bottom_box {
        color: $sub-color;

        .intro_main_text {
          font-size: 30px;
          // line-height: 50px;
          padding-bottom: 50px;
          font-family: "Pretendard_b";
          span {
            font-family: "Pretendard_b";
          }
        }

        .intro_sub_text_row {
          font-size: 20px;
          line-height: 35px;
          padding-bottom: 50px;
          span {
            font-family: "Pretendard_b";
          }
        }
      }

      .intro_sign_box {
        .sign_box {
          display: inline-block;
          vertical-align: text-bottom;

          &.right {
            width: 338px;
          }

          &.left {
            width: calc(100% - 338px);
          }

          .sign_text_box {
            font-size: 20px;

            span {
              font-family: "Pretendard_b";
            }
          }

          .sign_img {
            @include background-set(100%, 135px, contain);
            background-image: url("../image/sign_text.png");
          }
        }

        // .right_top_box {
        //   .image_box {
        //     display: inline-block;
        //     vertical-align: middle;

        //     .intro_image {
        //       @include background-set(200px, 200px, cover);
        //       background-image: url("../image/intro_image1.png");
        //       border-radius: 50%;
        //       background-position: center; /* 이미지 위치 설정 */
        //       overflow: hidden;
        //       width: 220px;
        //       height: 220px;
        //     }
        //   }

        //   .intro_title {
        //     display: inline-block;
        //     vertical-align: middle;
        //     padding-left: 10px;
        //     color: $sub-color;
        //     font-size: 35px;
        //     font-family: "Pretendard_b";

        //     span {
        //       color: #d87f38;
        //     }
        //   }
        // }
      }

      .history_container {
        border-left: 6px solid $main-color;
        position: relative;
        padding-bottom: 30px;

        &::before {
          position: absolute;
          content: "";
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 4px solid $main-color;
          background-color: #fff;
          top: 0;
          left: -13px;
        }

        &::after {
          position: absolute;
          content: "";
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 4px solid $main-color;
          background-color: $main-color;
          bottom: 0;
          left: -13px;
        }

        .history_contents_box {
          display: inline-block;
          vertical-align: top;
          padding: 60px 0 0 40px;

          .history_top_box {
            border-bottom: 1px solid #d0d0d0;
            padding-bottom: 35px;

            .logo_box {
              display: inline-block;
              vertical-align: top;
              width: 82px;

              .logo_img {
                @include background-set(100%, 86px, contain);
                background-image: url("../image/color_logo_2.png");
              }
              .logo_img_sub {
                @include background-set(100%, 86px, contain);
                background-image: url("../image/color_logo.png");
              }
            }

            .history_title_box {
              display: inline-block;
              vertical-align: top;
              padding-left: 30px;
              width: calc(100% - 82px);

              .title_text {
                color: #000;
                font-size: 30px;
                line-height: 50px;

                &:last-child {
                  font-family: "Pretendard_b";
                }

                span {
                  color: $main-color;
                }
              }
            }
          }

          .history_contents_row {
            padding-top: 80px;
            position: relative;

            &::after {
              position: absolute;
              content: "";
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border: 4px solid $main-color;
              background-color: $main-color;
              top: 85px;
              left: -53px;
            }

            .year_text {
              display: inline-block;
              vertical-align: top;
              padding-right: 20px;
              font-size: 30px;
              font-family: "Pretendard_b";
            }

            .year_contents_box {
              display: inline-block;
              vertical-align: top;
              border-left: 1px solid #d0d0d0;
              padding-left: 20px;
              width: calc(100% - 95px);

              .contents_row {
                .contents_text {
                  color: #5a5a5a;
                  padding-bottom: 15px;
                  font-size: 20px;
                  display: inline-block;
                  vertical-align: top;
                  width: calc(100% - 95px);
                }
                .contents_text p {
                  padding-top: 5px;
                  padding-left: 20px;
                  font-size: 17px;
                }

                .sub_year {
                  font-size: 20px;
                  color: $main-color;
                  font-family: "Pretendard_b";
                  padding-right: 13px;
                  height: inherit;
                  display: inline-block;
                  vertical-align: top;
                  width: 95px;
                }
              }
            }
          }
        }
      }

      .vision_container {
        padding-top: 60px;
        .vision_title_box {
          border-bottom: 1px solid $sub-color;
          color: $sub-color;
          padding-bottom: 10px;

          .logo_box {
            display: inline-block;
            vertical-align: middle;
            width: 35px;

            .logo_img {
              @include background-set(100%, 37px, contain);
              background-image: url("../image/color_image_s.png");
            }
          }

          .title_text {
            display: inline-block;
            vertical-align: middle;
            font-size: 23px;
            padding-left: 10px;
            width: calc(100% - 35px);
          }
        }
        .file_box {
          padding-top: 20px;
          text-align: right;
          .file_row {
            display: inline-block;
            vertical-align: middle;
            border: 1px solid #c9c9c9;
            border-radius: 5px;
            padding: 12px 14px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;

            .file_icon {
              display: inline-block;
              vertical-align: middle;
              @include background-set(9px, 19px, contain);
              background-image: url("../image/clip_icon.png");
            }

            .file_text {
              color: #656565;
              font-size: 15px;
              display: inline-block;
              vertical-align: middle;
              padding-left: 15px;
            }
          }
        }

        .founded_img_box {
          padding: 40px 0 60px;

          &.second {
            padding: 40px 0 95px;
          }

          &.third {
            padding: 40px 0;
          }

          &.fourth {
            padding: 40px 0 60px;
          }

          &.fifth {
            padding: 0;
          }

          &.sixth {
            padding: 60px 0 0;
          }

          &.vision {
            padding: 85px 0 170px;
          }

          &.enterprise_one {
            padding: 50px 0;
          }

          &.enterprise_two {
            padding: 0;
          }

          &.formation_one {
            padding: 40px 0 20px;
          }

          &.formation_two {
            padding: 0 0 150px;
          }
          .organization_img {
            @include background-set(100%, 50vw, contain);
            background-image: url("../image/organization_pc.png");
          }
          .founded_img {
            @include background-set(100%, 17vw, contain);
            background-image: url("../image/founded_img_1.png");

            &.second {
              height: 15vw;
              background-image: url("../image/founded_img_2.png");
            }

            &.third {
              height: 22vw;
              background-image: url("../image/founded_img_3.png");
            }

            &.fourth {
              height: 19vw;
              background-image: url("../image/founded_img_4.png");
            }

            &.fifth {
              height: 36vw;
              background-image: url("../image/founded_img_5.png");
            }

            &.sixth {
              height: 53vw;
              background-image: url("../image/founded_img_6.png");
            }

            &.seventh {
              height: 26vw;
              background-image: url("../image/vision_img_1.png");
            }

            &.eighth {
              height: 31vw;
              background-image: url("../image/vision_img_2.png");
            }

            &.nineth {
              height: 38vw;
              background-image: url("../image/vision_img_3.png");
            }

            &.enterprise_one {
              height: 35vw;
              background-image: url("../image/company_img_1.png");
            }

            &.enterprise_two {
              height: 24vw;
              background-image: url("../image/company_img_2.png");
            }

            &.human {
              height: 39vw;
              background-image: url("../image/human_img_1.png");
            }

            &.formation_one {
              height: 11vw;
              background-image: url("../image/formation_img_1.png");
            }

            &.formation_two {
              height: 29vw;
              background-image: url("../image/formation_img_2.png");
            }

            &.formation_three {
              height: 30vw;
              background-image: url("../image/formation_img_3.png");
            }
          }
        }

        .founded_text_box {
          padding: 0 0 75px 0;

          .founded_text_row {
            font-size: 20px;
            color: #707070;
            line-height: 35px;
            padding-bottom: 30px;

            &:last-child {
              padding-bottom: 0;
            }

            .text_title {
              font-family: "Pretendard_b";
            }

            .text_box {
              &.small {
                font-size: 15px;
                line-height: 25px;
              }
            }
          }
        }

        .introduce_image_box {
          padding-top: 10px;

          .introduce_image {
            @include background-set(100%, 30vw, cover);

            &.top {
              background-image: url("../image/introduce_image.png");
              margin-bottom: 37px;
            }

            &.bottom {
              background-image: url("../image/introduce_image_2.png");
            }

            /* iframe 스타일 */
            iframe {
              width: 100%;
              height: 30vw; /* 이미지와 동일한 비율로 설정 */
            }
          }
        }

        .vision_contents_box {
          padding: 65px 0 120px;

          &.center {
            text-align: center;
          }

          .middle_arrow {
            @include background-set(60px, 60px, cover);
            background-image: url("../image/middle_arrow.png");
            margin: 55px auto;
          }

          .second_box {
            .top_box {
              text-align: center;
              //padding-bottom: 17px;
              position: relative;
              width: 70%;
              margin: 0 auto;

              &.one {
                left: 0;
                margin: 0;
              }

              &.fif {
                right: 0;
              }

              &.short {
                width: 52.1%;
              }

              &.long {
                width: 66.8%;
                padding-top: 17px;
              }

              &.two {
                border-bottom: 2px dotted #c6c6c6;

                &::after {
                  position: absolute;
                  content: "";
                  border-left: 2px dotted #c6c6c6;
                  width: 1px;
                  height: 17px;
                  bottom: 0;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
              }

              &.three {
                &::after {
                  position: absolute;
                  content: "";
                  border-left: 2px dotted #c6c6c6;
                  width: 1px;
                  height: 100px;
                  bottom: -101px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
              }
            }

            .middle_box {
              padding-top: 33px;

              &.education {
                &.short {
                  padding-bottom: 33px;

                  .vision_row_box {
                    .vision_row {
                      &.second {
                        position: relative;

                        &::before {
                          position: absolute;
                          content: "";
                          border-left: 2px dotted #c6c6c6;
                          width: 1px;
                          height: 28px;
                          bottom: -28px;
                          left: 50%;
                          transform: translate(-50%, 0);
                        }
                      }
                    }
                  }
                }

                .vision_row_box {
                  width: calc((100% - 150px) / 3);

                  .vision_row {
                    &.second {
                      background-color: #fff;
                      color: $sub-color;
                      border: 1px solid $sub-color;

                      &.one {
                        border: 1px solid #03d68d;
                        color: #03d68d;
                      }
                    }

                    &.bottom {
                      border: 1px solid $sub-color;
                      min-height: 345px;
                      height: auto;
                    }
                  }
                }
              }
            }

            .vision_row_box {
              display: inline-block;
              vertical-align: top;
              width: calc((100% - 100px) / 3);
              margin-right: 50px;

              &:last-child {
                margin-right: 0;
              }
            }

            .vision_row {
              padding: 33px 0;
              border-radius: 64px;
              text-align: center;
              color: #496965;
              display: inline-block;
              vertical-align: middle;
              width: 100%;

              &.first {
                color: #fff;
                background-color: #00d68d;
                width: 200px;

                &.one {
                  background-color: #5adbaf;
                }

                &.two {
                  background-color: #88e6c6;
                }

                &.three {
                  position: relative;
                  background-color: #7cb9a5;

                  &::after {
                    position: absolute;
                    content: "";
                    border-left: 2px dotted #c6c6c6;
                    width: 1px;
                    height: 33px;
                    top: -33px;
                    left: 50%;
                    transform: translate(-50%, 0);
                  }
                }
              }

              &.second {
                color: #fff;
                background-color: #52cec3;
                position: relative;

                &::after {
                  position: absolute;
                  content: "";
                  border-left: 2px dotted #c6c6c6;
                  width: 1px;
                  height: 33px;
                  top: -33px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
              }

              &.bottom {
                background-color: #fff;
                border: 1px solid #52cec2;
                border-radius: 20px;
                height: 345px;
                margin-top: 28px;
                position: relative;
                padding: 30px 15px 15px;

                &::after {
                  position: absolute;
                  content: "";
                  border-left: 2px dotted #c6c6c6;
                  width: 1px;
                  height: 28px;
                  top: -28px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }

                .bottom_title {
                  font-family: "Pretendard_b";
                  color: $sub-color;
                  font-size: 15px;
                  padding-bottom: 34px;
                }

                .bottom_text_row {
                  padding-bottom: 20px;
                  font-size: 15px;
                  color: $sub-color;
                }

                .detail_btn {
                  border: 1px solid $sub-color;
                  border-radius: 20px;
                  padding: 8px 0;
                  cursor: pointer;

                  .detail_text {
                    display: inline-block;
                    vertical-align: middle;
                    padding-right: 10px;
                  }

                  .detail_arrow {
                    display: inline-block;
                    vertical-align: middle;
                    border-radius: 50%;
                    border: 1px solid $sub-color;
                    padding: 0 5px;
                  }
                }
              }
            }
          }

          .first_box {
            display: inline-block;
            vertical-align: top;
            width: 50%;

            &:last-child {
              padding-left: 35px;
            }

            &.w100 {
              padding-left: 0;
            }

            .left_box {
              display: inline-block;
              vertical-align: top;
              width: 170px;
            }

            .right_box {
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 170px);
              padding-left: 26px;

              .vision_row {
                border: 1px solid #03d68d;
                background-color: #fff;
                position: relative;

                &.long {
                  &::after {
                    width: 27px;
                    left: -27px;
                  }
                }

                &::after {
                  position: absolute;
                  content: "";
                  border-top: 2px dotted #03d68d;
                  width: 15px;
                  height: 1px;
                  top: 50%;
                  left: -15px;
                  transform: translate(0, -50%);
                }
              }
            }

            .vision_row {
              padding: 33px 0;
              width: 100%;
              border-radius: 64px;
              text-align: center;
              color: #496965;
              margin-bottom: 10px;

              span {
                color: #03d68d;
                font-family: "Pretendard_b";
                border-right: 1px solid #bfbfbf;
                padding-right: 10px;
                margin-right: 10px;
              }

              &.visible {
                display: none;
              }

              &.first {
                color: #fff;
                background-color: #00d68d;
              }

              &.second {
                color: #fff;
                background-color: #a0d9c6;
                position: relative;

                &::after {
                  position: absolute;
                  content: "";
                  border-left: 2px dotted #03d68d;
                  width: 1px;
                  height: 192px;
                  top: 46px;
                  right: -15px;
                }
              }

              &.third {
                color: #fff;
                background-color: #52cec4;
                position: relative;

                &::after {
                  position: absolute;
                  content: "";
                  border-left: 2px dotted #03d68d;
                  width: 1px;
                  height: 192px;
                  top: 46px;
                  right: -15px;
                }
              }

              &.fourth {
                color: #fff;
                background-color: #a0d9c6;
              }
            }
          }
        }
      }

      .field_container {
        .field_top_box {
          text-align: center;
        }

        .field_img_box {
          width: 100%;

          .field_img {
            @include background-set(100%, 40vw, contain);
            background-image: url("../image/field_img.png");
          }
        }
      }

      .map_container {
        .map_box {
          padding-bottom: 30px;
          // border-bottom: 1px solid #707070;

          .map {
            width: 100%;
            height: 550px;
          }
        }

        .map_info_box {
          .map_info_title {
            position: relative;
            margin-bottom: 15px;
            padding-left: 18px;
            line-height: 30px;
            font-size: 19px;
            font-weight: bold;
            color: #222;
            letter-spacing: -1px;
          }
          .map_info_content {
            border-top: 2px solid #75a99f;
            dl {
              padding: 15px;
              line-height: 20px;
              font-size: 15px;
              color: #333;
              border-bottom: 1px solid #e9e9e9;
              overflow: hidden;

              dt {
                float: left;
                padding: 0 35px;
                width: 150px;
                font-weight: bold;
              }
              dd {
                float: left;
                width: calc(100% - 150px);
                padding: 0 35px;
                color: #666;
              }
            }
          }
        }
        .traffic_box {
          padding-top: 30px;
          .traffic_title {
            position: relative;
            margin-bottom: 15px;
            padding-left: 18px;
            line-height: 30px;
            font-size: 19px;
            font-weight: bold;
            color: #222;
            letter-spacing: -1px;
          }
          .traffic_content {
            padding-top: 15px;
            border-top: 2px solid #75a99f;
            dl {
              line-height: 24px;
              padding-left: 25px;
              padding-bottom: 15px;
              dt {
                position: relative;
                margin-bottom: 10px;
                padding-left: 20px;
                line-height: 32px;
                font-size: 18px;
                font-weight: bold;
                color: #222;
                letter-spacing: -1px;
              }
              dt::before {
                content: "•"; /* 점 문자 */
                position: absolute;
                left: 0; /* 점의 위치를 왼쪽에 설정 */
                top: 0; /* 점의 위치를 상단에 설정 */
                color: black; /* 점의 색상 */
              }
              dd {
                display: block;
                margin-inline-start: 15px;
                unicode-bidi: isolate;
                span {
                  position: relative;
                  margin-top: 10px;
                  display: block;
                  position: relative;
                  padding-left: 20px;
                  font-size: 16px;
                  color: #666;
                }
                span::before {
                  content: "-"; /* 점 문자 */
                  position: absolute;
                  left: 0; /* 점의 위치를 왼쪽에 설정 */
                  top: 0; /* 점의 위치를 상단에 설정 */
                  color: black; /* 점의 색상 */
                }
                strong {
                  display: block;
                  margin-bottom: 10px;
                  font-size: 16px;
                  color: #333;
                }
              }
            }
          }
        }

        .map_text_box {
          .text_box {
            display: inline-block;
            vertical-align: top;
            width: 350px;
            padding-top: 34px;

            &.left {
              margin-right: 65px;
            }

            .map_title {
              color: #030303;
              font-size: 25px;
              font-family: "Pretendard_b";
              padding-bottom: 12px;
            }

            .map_text_row {
              .map_text {
                display: inline-block;
                vertical-align: top;
                padding-right: 20px;
                color: #030303;

                span {
                  padding-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .fusionSupportProgram {
      .fusionSupportProgram_img {
        background-image: url("../image/fusionSupportProgram_img.jpg");
        @include background-set(100%, 120vw, contain);
      }
      .chungnamInnovationProject_img {
        background-image: url("../image/chungnamInnovationProject_img.jpg");
        @include background-set(100%, 120vw, contain);
      }
      .chungnamInnovationCluster_img {
        background-image: url("../image/chungnamInnovationCluster_img.jpg");
        @include background-set(100%, 120vw, contain);
      }
      .carbonNeutralInfrastructure_img {
        background-image: url("../image/carbonNeutralInfrastructure_img.jpg");
        @include background-set(100%, 120vw, contain);
      }
      .localIndustryTalentDevelopment_img {
        background-image: url("../image/localIndustryTalentDevelopment_img.jpg");
        @include background-set(100%, 120vw, contain);
      }
      .koreanQuickStartProgram_img {
        background-image: url("../image/koreanQuickStartProgram_img.jpg");
        @include background-set(100%, 120vw, contain);
      }
      .smeResearchTalentSupport_img {
        background-image: url("../image/smeResearchTalentSupport_img.jpg");
        @include background-set(100%, 120vw, contain);
      }
      .chungnamIndustryAcademiaProgram_img {
        background-image: url("../image/chungnamIndustryAcademiaProgram_img.jpg");
        @include background-set(100%, 120vw, contain);
      }
      .youthVentureProgram_img {
        background-image: url("../image/youthVentureProgram_img.jpg");
        @include background-set(100%, 120vw, contain);
      }
      .dangjinStartupSupport_img {
        background-image: url("../image/dangjinStartupSupport_img.jpg");
        @include background-set(100%, 120vw, contain);
      }
      .hopeReturnPackage_img {
        background-image: url("../image/hopeReturnPackage_img.jpg");
        @include background-set(100%, 120vw, contain);
      }
      .hongseongIssueWarehouse_img {
        background-image: url("../image/hongseongIssueWarehouse_img.jpg");
        @include background-set(100%, 120vw, contain);
      }
      // img {
      //   width: 100%; /* 전체 너비 */
      // }
    }
  }
}

.lecture-room {
  padding: 40px 0px;
  margin: 10px;
}
.lecture-room h2 {
  padding-bottom: 20px;
}

.image-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
  padding-bottom: 10px;
}

.cnc-image-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-bottom: 10px;
}

.image {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
}

.room-image1 {
  background-image: url("../image/room_106_1.jpg");
}
.room-image2 {
  background-image: url("../image/room_106_2.jpg");
}
.room-image3 {
  background-image: url("../image/room_202_1.jpg");
}
.room-image4 {
  background-image: url("../image/room_202_2.jpg");
}
.c-room-image1 {
  background-image: url("../image/room_206_1.png");
}
.c-room-image2 {
  background-image: url("../image/room_207_1.png");
}
.p-room-image1 {
  background-image: url("../image/room_205_1.png");
}
.p-room-image2 {
  background-image: url("../image/room_205_2.png");
}
.vr-room-image1 {
  background-image: url("../image/room_203_1.png");
}
.vr-room-image2 {
  background-image: url("../image/room_203_2.jpg");
}
.cnc-room-image1 {
  background-image: url("../image/room_102_1.jpg");
}
.cnc-room-image2 {
  background-image: url("../image/room_102_2.jpg");
}
.cnc-room-image3 {
  background-image: url("../image/room_102_3.jpg");
}
.cnc-room-image4 {
  background-image: url("../image/room_102_4.jpg");
}
.description {
  text-align: left;
  font-size: 1.2em;
  margin: 10px 0;
}

@media (max-width: 1000px) {
  .icon_arrow_2 {
    display: inline-block;
    transform: rotate(90deg);
    transform-origin: center;
  }
  .image-container {
    flex-direction: column;
    align-items: center;
  }

  .image {
    height: 200px;
  }
  .description {
    text-align: left;
    font-size: 1em;
    margin: 10px 0;
  }
  .lecture-room {
    padding: 40px 20px;
    margin: 10px;
  }
  .sub_wrap .sub_back .sub_main_area.founded.empty {
    padding-bottom: 30px !important;
  }
}

.organization-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* 화면의 세로 중앙 정렬을 위해 */
  width: 100%; /* 가로 100% */
  padding-bottom: 30px;
}

.organization-btn {
  background-color: #03d68d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  display: block; /* inline-block에서 block으로 변경 */
  width: auto; /* 가로 50% */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin-left: -27px;
}

.organization-btn:hover {
  background-color: #02b37a;
}

@media (max-width: 768px) {
  .board-mobile {
    padding: 20px;
    border-bottom: 1px solid #e5e5e5; /* border 추가 */
  }
  .mobile-sub {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 17px;
  }

  .mobile-btm {
    font-size: 14px;
    padding-top: 8px;
  }

  .mobile-btm span {
    margin-right: 5px; /* 각 요소 사이의 간격 */
  }

  .clip_icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-image: url("../image/clip_icon.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
  }
}

.banner_txt_sub1 {
  text-align: right;
  padding-right: 60px;
}
.banner_txt_sub2 {
  text-align: right;
  padding-right: 90px;
}

.quick-menu {
  position: fixed;
  right: 100px;
  z-index: 4;
  // transform: translate(-50%, -50%);
  top: 40%;
  width: 120px;
  height: 440px;
  /* UI Properties */
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 35px;
  opacity: 1;
  .quick-menu-header {
    margin-top: 15px;
    .quick-menu-plus,
    .quick-menu-title {
      display: flex;
      justify-content: center;
      margin: 6px 0;
      font-size: 12px;
      color: #00db7e;
      opacity: 1;
    }
  }
  .quick-menu-item {
    display: flex;
    justify-content: center;
    .sub-menu-item {
      cursor: pointer;
      width: 110px;
      height: 71px;
      background: linear-gradient(to right, #00db7e, #00e2d9);
      /* 색상 값을 디자인에서 추출한 실제 값으로 교체하세요 */
      border-radius: 25px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      margin: 5px 0;
      font-size: 13px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &.top {
        background: #ffffff;
        border: 2px solid #00db7e;
        color: #00db7e;
      }
      .icon_formation {
        width: 24px; /* 아이콘의 너비 */
        height: 24px; /* 아이콘의 높이 */
        background-image: url("../image/quick_formation_icon.png");
        background-repeat: no-repeat;
        background-position: center;
        // background-size: 100% 100%;
        position: absolute; /* 텍스트와 겹치도록 설정 */
        top: 35%; /* 부모의 중앙에 배치 */
        left: 50%; /* 부모의 중앙에 배치 */
        transform: translate(-50%, -50%); /* 정확히 중앙으로 이동 */
        z-index: 1; /* 텍스트보다 위에 오도록 설정 */
      }

      .icon_inquiryinfo {
        width: 30px; /* 아이콘의 너비 */
        height: 30px; /* 아이콘의 높이 */
        background-image: url("../image/quick_inquiry_icon.png");
        background-repeat: no-repeat;
        background-position: center;
        // background-size: 100% 100%;
        position: absolute; /* 텍스트와 겹치도록 설정 */
        top: 35%; /* 부모의 중앙에 배치 */
        left: 50%; /* 부모의 중앙에 배치 */
        transform: translate(-50%, -50%); /* 정확히 중앙으로 이동 */
        z-index: 1; /* 텍스트보다 위에 오도록 설정 */
      }
      .icon_business {
        width: 30px; /* 아이콘의 너비 */
        height: 30px; /* 아이콘의 높이 */
        background-image: url("../image/quick_business_icon.png");
        background-repeat: no-repeat;
        background-position: center;
        // background-size: 100% 100%;
        position: absolute; /* 텍스트와 겹치도록 설정 */
        top: 35%; /* 부모의 중앙에 배치 */
        left: 50%; /* 부모의 중앙에 배치 */
        transform: translate(-50%, -50%); /* 정확히 중앙으로 이동 */
        z-index: 1; /* 텍스트보다 위에 오도록 설정 */
      }
      .icon_facility {
        width: 30px; /* 아이콘의 너비 */
        height: 30px; /* 아이콘의 높이 */
        background-image: url("../image/quick_facility_icon.png");
        background-repeat: no-repeat;
        background-position: center;
        // background-size: 100% 100%;
        position: absolute; /* 텍스트와 겹치도록 설정 */
        top: 35%; /* 부모의 중앙에 배치 */
        left: 50%; /* 부모의 중앙에 배치 */
        transform: translate(-50%, -50%); /* 정확히 중앙으로 이동 */
        z-index: 1; /* 텍스트보다 위에 오도록 설정 */
      }
      .icon_top {
        width: 30px; /* 아이콘의 너비 */
        height: 30px; /* 아이콘의 높이 */
        background-image: url("../image/quick_top_icon.png");
        background-repeat: no-repeat;
        background-position: center;
        // background-size: 100% 100%;
        position: absolute; /* 텍스트와 겹치도록 설정 */
        top: 35%; /* 부모의 중앙에 배치 */
        left: 50%; /* 부모의 중앙에 배치 */
        transform: translate(-50%, -50%); /* 정확히 중앙으로 이동 */
        z-index: 1; /* 텍스트보다 위에 오도록 설정 */
      }

      .text {
        font-size: 14px; /* 텍스트 크기 조정 */
        color: #ffffff;
        position: relative;
        z-index: 2; /* 아이콘 위에 텍스트가 표시되도록 설정 */
        top: 50%;
      }
      .text.top {
        font-size: 14px; /* 텍스트 크기 조정 */
        color: #00db7e;
        position: relative;
        z-index: 2; /* 아이콘 위에 텍스트가 표시되도록 설정 */
        top: 50%;
      }
    }
  }
  // .quick-menu-item {
  //   cursor: pointer;
  //   @include background-set(70px, 70px, contain);
  //   background-image: url("../image/scroll_icon.png");
  //   .sub-menu {
  //     position: absolute;
  //     top: 100%;
  //     left: 0;
  //     width: 100%;
  //     background-color: #444;
  //     box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  //     color: black;
  //     .sub-menu-item {
  //       padding: 10px;
  //       color: white;
  //       text-align: center;
  //       border-bottom: 1px solid #555;
  //       .link-item {
  //         color: white; /* 링크 텍스트 색상 */
  //         text-decoration: none; /* 링크 밑줄 제거 */
  //       }
  //     }
  //     .sub-menu-item:last-child {
  //       border-bottom: none;
  //     }

  //     .sub-menu-item:hover {
  //       background-color: #666;
  //     }
  //   }
  // }
}
.myCalendar {
  background-color: white;
  width: 100%;
  height: 550px;
  padding: 20px;
  box-shadow: 0px 0px 10px 2px rgba(241, 241, 241, 0.904);
  // box-shadow: 0px 0px 10px 2px #f6f7f7;
  .calendar_date {
    display: flex;
    .calendar_date_yymm {
      font-weight: bold;
      font-size: 30px;
      width: 136px;
      height: 46px;
      /* UI Properties */
      border: 1.5px solid #e2e2e2;
      border-radius: 5px;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .calendar_change {
      margin-left: 7px;
      /* Layout Properties */
      // position: absolute;
      top: 416px;
      left: 373px;
      width: 79px;
      height: 46px;
      /* UI Properties */
      border: 1.5px solid #e2e2e2;
      border-radius: 5px;
      opacity: 1;

      background: transparent url("../image/line.png") 0% 0% no-repeat
        padding-box;
      background-position: center; /* 가운데 정렬 */
      background-size: 1.5% 70%;
      display: flex; /* Flexbox 사용 */
      justify-content: space-between; /* 자식 요소를 양 끝에 배치 */
      align-items: center; /* 수직 중앙 정렬 */
      .calendar_prev,
      .calendar_next {
        width: 100%;
        height: 100%;
        background-size: contain; /* 이미지 크기 조정 */
        background-repeat: no-repeat; /* 이미지 반복 안함 */
        cursor: pointer;
      }
      .calendar_prev {
        background: transparent url("../image/prev.png") center center no-repeat;
      }

      .calendar_next {
        background: transparent url("../image/next.png") center center no-repeat;
      }
    }
  }

  .calendar_box {
    width: 100%;
    height: calc(100% - 80px);
    border-collapse: collapse; /* 테이블 셀 간의 간격 제거 */
    margin-top: 10px;
    td {
      width: calc(100% / 7); /* 각 셀의 너비를 7등분하여 설정 */
      height: calc(100% / 6); /* 각 셀의 높이를 6등분하여 설정 */
      text-align: center; /* 텍스트 가운데 정렬 */
      box-sizing: border-box; /* 경계선 크기를 포함하도록 설정 */
      position: relative;
      vertical-align: middle;
    }
    th {
      height: 60px; /* 헤더 셀의 높이 */
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      font-weight: bold;
    }
    .sun {
      color: rgba(255, 49, 49, 0.973);
    }
    .sat {
      color: rgb(41, 126, 253);
    }
    .calendar_List {
      text-align: center;
      border: #000;

      td:first-of-type .day {
        position: relative;
        // &::after {
        //   content: "";
        //   position: absolute;
        //   width: 60px; // 너비
        //   height: 60px; // 높이
        //   transform: translate(-50%, -50%);
        // }
        // &.end-date {
        //   position: relative;
        //   &::after {
        //     content: "";
        //     position: absolute;
        //     width: 60px;
        //     left: -22px;
        //   }
        // }
      }

      td:last-of-type .day {
        position: relative;
        // &::after {
        //   content: "";
        //   position: absolute;
        //   width: 0px; // 너비
        //   height: 0px; // 높이
        //   transform: translate(-50%, -50%);
        //   padding-right: 50px;
        // }
        // &.start-date {
        //   position: relative;
        //   &::after {
        //     content: "";
        //     position: absolute;
        //     width: 60px;
        //     left: -22px;
        //   }
        // }
      }

      .day {
        // cursor: pointer;
        position: relative; /* span을 동그라미 안에 위치시키기 위해 설정 */
        display: inline-flex; /* span 내부의 콘텐츠를 flexbox로 배치 */
        align-items: center; /* 수직 중앙 정렬 */
        justify-content: center; /* 수평 중앙 정렬 */
        z-index: 1;
        &.today {
          border-radius: 50%;
          width: 50px; /* 너비와 높이 비율을 1:1로 설정 */
          height: 50px; /* 너비와 높이 비율을 1:1로 설정 */
          border: 2px solid #75a99f;
        }

        &.possible {
          cursor: pointer;
        }

        &.disabled {
          color: rgb(170, 170, 170);
          &.sun {
            color: rgba(255, 49, 49, 0.973);
          }
          &.sat {
            color: rgb(41, 126, 253);
          }
        }
        &.in-range {
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 50px; /* 너비와 높이 비율을 1:1로 설정 */
            height: 50px; /* 너비와 높이 비율을 1:1로 설정 */
            border: 2px solid #ecfff3;
            background-color: #beffd6;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            z-index: -2;
          }
        }
        &.start-date::before,
        &.end-date::before {
          content: "";
          position: absolute;
          // content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 50px; /* 너비와 높이 비율을 1:1로 설정 */
          height: 50px; /* 너비와 높이 비율을 1:1로 설정 */
          border: 2px solid #75a99f;
          background-color: #75a99f;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
        &.start-date,
        &.end-date {
          color: white;
        }
      }
    }
    // .calendar_box td.start-date,
    // .calendar_box td.end-date {
    //   background-color: #90caf9; /* 시작 및 종료 날짜의 배경색 */
    // }
  }
}

.search_wrap {
  background-color: #f8f8f8;
  min-height: 80vh;
  .search_back {
    .search_container {
      width: 60%;
      margin: 0 auto;

      .title_box {
        padding: 50px 0 10px 0;
        text-align: center;
        .title_text {
          font-size: 30px;
        }
        .total_count {
          font-size: 16px;
          color: #777777;
          margin: 15px 0;
        }
      }
      .list_area {
        width: 100%;
        padding-bottom: 100px;
        .search_box {
          text-align: center;
          position: relative;
          box-shadow: 0px 5px 10px #0000000b;
          background-color: #fff;
          border-radius: 10px;
          // padding: 15px;
          height: 60px;
          .search_input {
            width: 100%;
            height: 100%;
            display: inline-block;
            padding: 15px 20px;
            border-radius: 10px;
            font-size: 16px;
            &::placeholder {
              color: #c3c3c3;
            }
          }
          .search_btn {
            position: absolute;
            @include background-set(19px, 19px, contain);
            background-image: url("../image/search_icon.png");
            top: 50%;
            right: 25px;
            transform: translate(0, -50%);
            cursor: pointer;
          }
        }
        .list_box {
          margin: 50px 0;

          .board_title {
            font-size: 25px;
          }
          .board_count {
            font-size: 15px;
            color: #777777;
            margin: 15px 0;
          }
          // .board_table {
          //   width: 100%;
          //   box-shadow: 0px 5px 10px #0000000b;
          //   background-color: #fff;
          //   border-radius: 5px;
          //   // overflow-x: auto;
          //   border-collapse: collapse;
          .board_body {
            width: 100%;
            box-shadow: 0px 5px 10px #0000000b;
            background-color: #fff;
            border-radius: 5px;
            // overflow-x: auto;
            border-collapse: collapse;
            padding: 0 20px;
            display: block;
            .body_row {
              display: flex;
              border-bottom: 1px solid #f0f0f0;
              cursor: pointer;
              &:last-child {
                border-bottom: none;
              }
              .row_wrap {
                // border-bottom: 1px solid #f0f0f0;
                font-size: 15px;
                color: #030303;
                padding: 18px 5px;
                display: flex;
                width: 100%;
                justify-content: center; /* 수평 가운데 정렬 */
                .row_title {
                  flex: 1; /* title이 가질 수 있는 최대 너비를 가짐 */
                  margin-right: 10px; /* title과 date 사이에 약간의 간격 */
                }
                .row_date {
                  width: 90px;
                }
                .no_data {
                  cursor: default;
                  font-size: 15px;
                }
              }
            }
          }
          // }
        }
      }
    }
  }
}

.color-red {
  color: red;
}
.color-blue {
  color: blue;
}

.subtab-wrap {
  width: 60%;
  margin: 0 auto;
}

.subtab-content-box {
  border-radius: 24px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 0 30px 0;
  text-align: center;
}

.subtab-background {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 20px 30px;
  border-radius: 27px;
}

.subtab-s1 {
  font-size: 16px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  color: #5a5a5a;

  // &.active {
  //   color: $sub-color;
  //   font-family: "Pretendard_b";
  // }
}

.subtab-wrap div:not(:last-child)::after {
  content: "|";
  margin-left: 15px;
  margin-right: 15px;
  color: #e3e3e3;
}

.project-img1 {
  height: 0;
  padding-top: 32.5%;
  position: relative;
  background-image: url("../image/project_1.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.fusion-img1 {
  height: 0;
  padding-top: 32.5%;
  position: relative;
  background-image: url("../image/fusion_1.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.carbon-img1 {
  height: 0;
  padding-top: 32.5%;
  position: relative;
  background-image: url("../image/carbon_1.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.cluster-img1 {
  height: 0;
  padding-top: 32.5%;
  position: relative;
  background-image: url("../image/cluster_1.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.dangjin-img1 {
  height: 0;
  padding-top: 32.5%;
  position: relative;
  background-image: url("../image/dangjin_1.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.hope-img1 {
  height: 0;
  padding-top: 32.5%;
  position: relative;
  background-image: url("../image/hope_1.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.hongsung-img1 {
  height: 0;
  padding-top: 32.5%;
  position: relative;
  background-image: url("../image/hongsung_1.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.local-img1 {
  height: 0;
  padding-top: 32.5%;
  position: relative;
  background-image: url("../image/local_1.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.korea-img1 {
  height: 0;
  padding-top: 32.5%;
  position: relative;
  background-image: url("../image/korea_1.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.sme-img1 {
  height: 0;
  padding-top: 32.5%;
  position: relative;
  background-image: url("../image/sme_1.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.academia-img1 {
  height: 0;
  padding-top: 32.5%;
  position: relative;
  background-image: url("../image/academia_1.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.youth-img1 {
  height: 0;
  padding-top: 32.5%;
  position: relative;
  background-image: url("../image/youth_1.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.business_img_box {
  padding: 30px 0 60px;
  width: 100%;
  .business_img {
    background-color: #5a5a5a;
    width: 100%;
    height: 400px;
  }
}
.propulsion_img_box {
  padding: 30px 0 60px;
  width: 100%;
  .propulsion_img {
    background-color: #5a5a5a;
    width: 100%;
    height: 500px;
  }
}

.objective_box {
  padding: 40px 0;
  .objective_title {
    &.first {
      color: #d87f38;
      font-size: 24px;
      font-family: Pretendard_b;
      line-height: 1.5;
      letter-spacing: 1.2px;
    }
    &.second {
      color: #d87f38;
      font-size: 32px;
      font-family: Pretendard_b;
    }
    &.third {
      color: #4faaa1;
      font-size: 48px;
      font-family: Pretendard_b;
    }
    &.center {
      text-align: center;
    }
  }

  .objective_content_box {
    padding: 40px 0 0px;
    display: flex;
    .objective_content {
      margin-right: 20px;
      width: calc((100% - 40px) / 3);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content_title {
        height: 60px;
        width: 100%;
        // background-color: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        border-radius: 35px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        font-family: Pretendard_b;
        // border: 3px solid #4faaa1;
        color: #ffffff;
        &.first {
          background-color: #3a6ca9;
        }
        &.second {
          background-color: #f2927c;
        }
        &.third {
          background-color: #38baca;
        }
      }
      .content_text_box {
        padding: 30px 0 0px;
        .content_text {
          font-size: 20px;
          position: relative;
          margin-bottom: 10px;
          color: #707070;
        }
      }
    }
    .objective_content:nth-child(3) {
      margin-right: 0px;
    }

    .objective_img_content_box {
      width: 50%;
      height: 300px;
    }
    .hongsung-img3 {
      height: 0;
      padding-bottom: 73%;
      position: relative;
      background-image: url("../image/hongsung_3.png");
      background-size: contain;
      background-position: right center;
      background-repeat: no-repeat;
    }
    .content_icon {
      padding: 0 30px;
      display: flex;
      align-items: center;
    }
    .objective_text_content_box {
      width: 50%;
      overflow-wrap: break-word;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .content_text::before {
        content: "•";
        position: absolute;
        left: 0;
        top: 0;
        color: 707070;
        font-size: 22px;
      }
      .content_text {
        font-size: 20px;
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
        color: #707070;
        //다현
        &.hongseong_txt {
          font-size: 18px;
        }
      }
    }
  }
  .objective_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }
}

.final_result_box {
  padding: 40px 0 60px;
  // 기홍님한테 받은 테이블 css
  .project-result-box {
    .project-result-table {
      width: 100%;
      border-collapse: collapse;
      margin: 20px 0;
      .slash {
        background-image: linear-gradient(
          to right bottom,
          transparent calc(50% - 1px),
          #c0c0c0,
          transparent calc(50% + 1px)
        );
        background-size: 120% 120%;
        background-position: center;
      }
      .content_text_parent {
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;
        .content_text_child {
          position: relative;
          padding-left: 10px;
        }
        .content_text_child::before {
          content: "-";
          left: 0;
          position: absolute;
          top: 0;
        }
      }
      .content_text_parent::before {
        content: "•";
        left: 0;
        position: absolute;
        top: 0;
      }
    }

    .project-result-table th,
    .project-result-table td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: center;
    }

    .project-result-table th {
      background-color: #f4f4f4;
      font-weight: bold;
    }

    .project-result-table td {
      background-color: #fff;
      vertical-align: middle;

      &.table-left {
        text-align: left;
      }
    }

    .project-result-table td[rowSpan] {
      vertical-align: middle; /* 세로로 정렬 */
    }
  }

  .result_content_box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    .result_content_title {
      width: 120px;
      height: 180px;
      background-color: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
      border-radius: 35px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-family: Pretendard_b;
      border: 3px solid #4faaa1;
      color: #000000;
    }
    .result_content_text_box {
      position: relative;
      padding-left: 20px;
      .content_text {
        font-size: 20px;
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
        color: #707070;
      }
      .content_text::before {
        color: #707070;
        content: "•";
        left: 0;
        position: absolute;
        top: 0;
      }
    }

    .result_inner_box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 120px;

      .result_content_item {
        width: 130px;
        height: 130px;
        background-color: #ffffff;
        // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        font-family: Pretendard_b;
        border: 20px solid #4faaa1;
        color: #000000;
      }

      .content_item_box {
        position: relative;
        .content_item_round {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(-50%, -50%);
          width: 80px;
          height: 80px;
          border-radius: 50%;
          border: 5px solid #ffffff;
          z-index: 0;
        }
        .content_item {
          position: relative;
          z-index: 1;
          border-radius: 30px;
          text-align: center;
          margin-bottom: 5px;
          height: 50px;
          font-size: 22px;
          font-family: Pretendard_b;
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.first {
            background: linear-gradient(to right, #82bd8d, #2badba);
          }
          &.second {
            background: linear-gradient(to right, #85c3ea, #3794b5);
          }
        }
      }
      .content_item_text {
        width: 50%;
        font-size: 22px;
        font-family: Pretendard_b;
        margin-right: 20px;
        text-align: center;
      }
      .inner_item_box {
        width: 350px;
        height: 110px;
        padding: 5px 10px;
        background-color: #ffffff;
        border-radius: 30px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border: 2px solid #4faaa1;
        text-align: center;
        margin-right: 10px;
        &.division {
         display:flex;
         justify-content: center;
         align-items: center;
         vertical-align: middle;
        }
        .item_title {
          font-family: Pretendard_b;
          background-color: #4faaa1;
          border-radius: 20px;
          padding: 5px;
          color: #ffffff;
          margin-bottom: 10px;
        }
        .item_content {
          color: #000000;
          line-height: 1.5;
          font-family: Pretendard_b;
          &.title-none {
            font-size: 18px;
            padding-top: 5px;
          }
          p {
            font-size: 16px;
            color: #707070;
          }
        }
      }
      .inner_item_box:nth-child(5) {
        margin-right: 0px;
      }
      .content_icon {
        padding: 0 10px;
        display: flex;
        align-items: center;
      }
    }
  }
  .result_title_box {
    width: 100%;
    height: 70px;
    background: linear-gradient(to right, #f18e3b, #ce2663);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 33px;
    font-family: Pretendard_b;
    color: #ffffff;
  }
}
.propulsion_content_box.vision {
  width: 88%;
  margin: 0 auto;
}
.propulsion_box {
  padding: 40px 0 60px;
  .propulsion_content_box {
    .propulsion_content {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .propulsion_content_title {
        align-items: center;
        background-color: #fff;
        border: 3px solid #4faaa1;
        border-radius: 20px;
        box-shadow: 0 3px 6px #0000001a;
        color: #000;
        display: flex;
        font-family: Pretendard_b;
        font-size: 28px;
        height: 70px;
        justify-content: center;
        text-align: center;
        width: 150px;
      }
      .propulsion_content_text_box {
        position: relative;
        padding-left: 20px;
        .content_text {
          font-size: 28px;
          position: relative;
          padding-left: 20px;
          margin-bottom: 10px;
          color: #4faaa1;
        }
      }
    }
  }
  .propulsion_div_content_box {
    display: flex;
    align-items: center;
    .propulsion_div_content {
      // margin-right: 50px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.first {
        width: 25%;
      }
      &.second {
        width: 30%;
      }
      &.third {
        width: 45%;
      }
      &.icon {
        width: 60px;
      }
      .content_top {
        height: 60px;
        width: 100%;
        // background-color: #3a6ca9;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        font-family: Pretendard_b;
        color: #ffffff;
        margin-bottom: 20px;
        &.first {
          background-color: #3a6ca9;
        }
        &.second {
          background-color: #f2927c;
        }
        &.third {
          background-color: #38baca;
        }
      }
      .content_middle,
      .content_bottom {
        height: 200px;
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-bottom: 20px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .content_text {
          font-size: 22px;
          color: #000000;
          font-family: Pretendard_b;
          text-align: left;
        }
      }
      .content_top_icon {
        height: 60px;
        margin-bottom: 20px;
      }
      .content_middle_icon,
      .content_bottom_icon {
        height: 200px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
      }
    }
  }
  .propulsion_text_content_box {
    display: flex;
    margin: 0;
    //   align-items: center;
    //   margin-bottom: 20px;
    //   width: 100%;
    .text_content_header {
      width: 200px;
      margin-right: 20px;
      background-color: #4faaa1;
      // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      font-family: Pretendard_b;
      // border: 3px solid #4faaa1;
      color: #ffffff;
    }
    .text_content_body_box {
      //     position: relative;
      //     padding-left: 20px;
      width: 100%;
      .content_title {
        // width: 100%;
        background-color: #9db0db;
        font-size: 22px;
        margin-bottom: 12px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px;
        &.none {
          background-color: transparent;
        }
      }
      .content_text {
        font-size: 18px;
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
        color: #707070;
        line-height: 1;
      }
      .content_text::before {
        color: #707070;
        content: "•";
        left: 0;
        position: absolute;
        top: 0;
      }
    }
    .text_content_plus {
      width: 200px;
      margin-right: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-family: Pretendard_b;
      color: #acacac;
    }
  }
}

.business_inner_logo_box {
  margin: 20px 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo_content {
    width: 100%;
    display: flex;
    align-items: center;
    &.hongseong {
      justify-content: right;
      margin-right: 30px;
      overflow: hidden;
    }
    &.main {
      justify-content: left;
      margin-left: 30px;
      overflow: hidden;
    }
    .logo_hongseong {
      background-image: url("./../image/hongsung_logo.png");
      display: block;
      width: 280px;
      height: 70px;
      background-size: contain; /* 아이콘 크기 조정 */
      background-repeat: no-repeat;
      background-position: center;
    }
    .logo_main {
      background-image: url("./../image/header_logo.png");
      display: block;
      width: 280px;
      height: 70px;
      background-size: contain; /* 아이콘 크기 조정 */
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
.business_content_box {
  padding: 30px 0 60px;
  .business_content {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .business_content_title {
      width: 130px;
      height: 130px;
      background-color: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-family: Pretendard_b;
      border: 10px solid #4faaa1;
      color: #000000;
    }
    .business_content_text_box {
      position: relative;
      padding-left: 20px;
      flex: 1;
      .content_text {
        font-size: 20px;
        position: relative;
        padding-left: 20px;
        // margin-bottom: 10px;
        color: #707070;
      }
    }
  }
}

.sme-img2 {
  height: 0;
  padding-top: 50%;
  position: relative;
  background-image: url("../image/sme_2.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.dangjin-img2 {
  height: 0;
  padding-top: 50%;
  position: relative;
  background-image: url("../image/dangjin_2.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.korea-img2 {
  height: 0;
  padding-top: 67%;
  position: relative;
  background-image: url("../image/korea_2.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 30px;
}

.project-img2 {
  height: 0;
  padding-top: 65%;
  position: relative;
  background-image: url("../image/project_2.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.local-img2 {
  height: 0;
  padding-top: 50%;
  position: relative;
  background-image: url("../image/local_2.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.carbon-img2 {
  height: 0;
  padding-top: 44%;
  position: relative;
  background-image: url("../image/carbon_02.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.fusion-img2 {
  height: 0;
  padding-top: 45%;
  position: relative;
  background-image: url("../image/fusion_2.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.hongsung-img2 {
  height: 0;
  padding-top: 56%;
  position: relative;
  background-image: url("../image/hongsung_2.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.hope-img2 {
  height: 0;
  padding-top: 47%;
  position: relative;
  background-image: url("../image/hope_2.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.cluster-img2 {
  background-image: url("../image/cluster_2.jpg");
  height: 0;
  padding-top: 50%;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.youth-img2 {
  background-image: url("../image/youth_2.jpg");
  height: 0;
  padding-top: 50%;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.dangjin-img3 {
  height: 0;
  padding-top: 44%;
  position: relative;
  background-image: url("../image/dangjin_3.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.sme-img3 {
  height: 0;
  padding-top: 65%;
  position: relative;
  background-image: url("../image/sme_3.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.project-img3 {
  background-image: url("../image/project_3.jpg");
  height: 0;
  padding-top: 50%;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.fusion-img3 {
  height: 0;
  padding-top: 56%;
  position: relative;
  background-image: url("../image/fusion_3.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.cluster-img3 {
  background-image: url("../image/cluster_3.jpg");
  height: 0;
  padding-top: 50%;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.youth-img3 {
  background-image: url("../image/youth_3.jpg");
  height: 0;
  padding-top: 62%;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.sme-img4 {
  height: 0;
  padding-top: 44%;
  position: relative;
  background-image: url("../image/sme_4.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.final_result_box {
  padding: 40px 0 60px;
  .result_content_box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .result_content_title {
      width: 180px;
      height: 130px;
      background-color: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      font-family: Pretendard_b;
      border: 3px solid #4faaa1;
      color: #000000;
    }
    .result_content_text_box {
      position: relative;
      padding-left: 25px;
      .content_text {
        font-size: 20px;
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
        color: #707070;
      }
      .content_text::before {
        color: #707070;
        content: "•";
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }
  .table-container {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
.final_result_box {
  padding: 40px 0 60px;
  .cluster-txt {
    font-size: 25px;
    letter-spacing: 1.2px;
    font-family: "Pretendard_b";
    color: #4faaa1;
  }
  .cluster-txt:not(:first-child) {
    padding-top: 12px;
  }
  .short_term_box {
    display: flex;
    align-items: center;
    .short_term_title {
      width: 120px;
      height: 180px;
      background-color: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
      border-radius: 35px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-family: Pretendard_b;
      border: 3px solid #4faaa1;
      color: #000000;
    }
    .short_term_content {
      position: relative;
      padding-left: 20px;
      .content_text {
        font-size: 20px;
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
        color: #707070;
      }
      .content_text::before {
        color: #707070;
        content: "•";
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }
  /* 기업지원사업 - 유기홍 */
  .project-result-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }

  .project-result-table th,
  .project-result-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  .project-result-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    color: #707070;
    letter-spacing: 1.1px;
  }

  .project-result-table td {
    background-color: #fff;
    vertical-align: middle;
    color: #707070;

    &.table-left {
      text-align: left;
    }
  }

  .project-result-table td[rowSpan] {
    vertical-align: middle; /* 세로로 정렬 */
  }
  .project-result-table td span {
    padding-left: 10px;
  }
}

/* 탄소중립 실증 인프라구축사업 */
.carbon-work-wrap {
  display: flex;
  width: 100%;
  font-size: 19px;
  box-sizing: border-box;
}

.carbon-content-left {
  width: 20%;
  text-align: center;
  border: 1px solid #c6c6c6;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 9px 0;
  background: linear-gradient(to right, #4faaa1, #3a8b80);
  border-right: none;
  color: #fff;
  font-family: "Pretendard_b";
  box-sizing: border-box;
}

.carbon-content-right {
  width: 80%; /* 데스크탑에서 80% 폭 차지 */
  text-align: center;
  border: 1px solid #c6c6c6;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 9px 0;
  border-left: none;
  font-family: "Pretendard_b";
  box-sizing: border-box;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
}

.carbon-work-wrap:not(:first-child) {
  padding-top: 10px;
}

/* 탄소중립 추진체계 */
.carbon-floor-wrap {
  display: flex;
  width: 100%;
  font-size: 19px;
  box-sizing: border-box;
}

.carbon-floor-left {
  width: 20%;
  text-align: center;
  border-radius: 10px;
  padding: 9px 0;
  background: linear-gradient(to right, #4faaa1, #3a8b80);
  border-right: none;
  color: #fff;
  font-family: "Pretendard_b";
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  padding: 30px;
}

.carbon-floor-left span {
  display: block;
  font-size: 18px;
}
.carbon-floor-left span.CTP {
  font-size: 32px !important;
}
.sub-txt-kcl {
  padding-left: 14px;
  color: gray;
  font-size: 17px;
}
.sub-txt-ctp {
  padding-left: 14px;
}

.carbon_img_box {
  padding: 40px 0 60px;
  width: 100%;
}

.carbon-floor-left.first {
  background: #df6e36;
}
.carbon-floor-left.second {
  background: #72206a;
}
.carbon-floor-left.third {
  background: #367838;
}
.carbon-floor-left.fourth {
  background: #14465d;
}

.carbon-floor-right {
  margin-left: 0.5%;
  width: 79.5%; /* 데스크탑에서 80% 폭 차지 */
  text-align: left;
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  font-family: "Pretendard_b";
  box-sizing: border-box;
  padding: 25px;
  line-height: 1.6;
}

.carbon-floor-right.first {
  box-shadow: inset 0 0 0 2px rgba(223, 110, 54, 0.2);
}

.carbon-floor-right.second {
  box-shadow: inset 0 0 0 2px rgba(114, 32, 106, 0.2);
}

.carbon-floor-right.third {
  box-shadow: inset 0 0 0 2px rgba(54, 120, 56, 0.2);
}

.carbon-floor-right.fourth {
  box-shadow: inset 0 0 0 2px rgba(20, 70, 93, 0.2);
}

.carbon-floor-wrap:not(:first-child) {
  padding-top: 10px;
}

.objective_box_project {
  display: flex;
  justify-content: space-between;
  padding: 40px 0 60px;
  .object-txt {
    &.project {
      color: #707070;
      font-size: 22px;
      font-family: Pretendard_b;
      letter-spacing: 1.2px;

      & span {
        color: #4faaa1;
        font-size: 26px;
      }
    }
  }
}

.icon_arrow_1 {
  display: inline-block;
  background-image: url("./../image/arrow_1.png");
  width: 60px;
  height: 60px;
  background-size: contain; /* 아이콘 크기 조정 */
  background-repeat: no-repeat;
  background-position: center;
}
.icon_arrow_2 {
  display: inline-block;
  background-image: url("./../image/arrow_2.png");
  width: 40px;
  height: 40px;
  background-size: contain; /* 아이콘 크기 조정 */
  background-repeat: no-repeat;
  background-position: center;
}
.icon_arrow_3 {
  display: inline-block;
  background-image: url("./../image/arrow_3.png");
  width: 40px;
  height: 40px;
  background-size: contain; /* 아이콘 크기 조정 */
  background-repeat: no-repeat;
  background-position: center;
}
.icon_arrow_4 {
  display: inline-block;
  background-image: url("./../image/arrow_4.png");
  width: 40px;
  height: 40px;
  background-size: contain; /* 아이콘 크기 조정 */
  background-repeat: no-repeat;
  background-position: center;
}
.icon_arrow_hongsung {
  display: inline-block;
  background-image: url("./../image/arrow_2.png");
  width: 40px;
  height: 40px;
  background-size: contain; /* 아이콘 크기 조정 */
  background-repeat: no-repeat;
  background-position: center;
}
.business_text_box {
  padding: 40px 0 60px;
  .business_txt {
    margin-bottom: 10px;
    padding-left: 17px;
    line-height: 28px;
    font-size: 20px;
    font-family: "Pretendard_b";
    color: #707070;
    letter-spacing: -1px;
    position: relative;
  }
  .business_txt::before {
    content: "•";
    position: absolute;
    left: 0;
    top: 0;
    color: #707070;
  }
}

.academia-floor-wrap:last-child {
  margin-bottom: 0px;
}

.academia-floor-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  margin-bottom: 40px;
  .academia-floor-item {
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    text-align: center;
    // margin-right: 10px;
    width: calc((100%) / 3);
    .academia-floor-item-head {
      background: linear-gradient(to right, #f18e3b, #ce2663);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      width: 100%;
      height: 25%;
      color: #ffffff;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .academia-floor-item-body {
      height: 50%;
      color: #000000;
      line-height: 1.3;
      font-family: Pretendard_b;
      font-size: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .academia-floor-item-footer {
      width: 100%;
      height: 25%;
      p {
        margin-top: auto;
        font-size: 26px;
        color: #707070;
      }
    }
  }
  .academia-floor-item:last-child {
    margin-right: 0px;
  }
  .content_icon {
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
}
.academia_objective_content_left {
  width: 60%;
  .academia_objective_item {
    border-radius: 20px;
    background: linear-gradient(to right, #2badba, #82bd8d);
    font-size: 28px;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
}

.academia_objective_content_right {
  width: 35%;
  .academia_objective_item {
    width: 100%;
    border-radius: 20px;
    background: linear-gradient(to right, #f18e3b, #ce2663);
    font-size: 28px;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    color: #ffffff;
  }
}

.final_result_head {
  padding-bottom: 40px;
  padding-left: 17px;
  line-height: 28px;
  font-size: 22px;
  font-family: "Pretendard_b";
  color: #707070;
  letter-spacing: -1px;
  position: relative;
}
.final_result_head::before {
  content: "•";
  position: absolute;
  left: 0;
  top: 0;
  color: #707070;
}

.final_result_body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 10px;
  .final_result_item1 {
    grid-column: 1;
    grid-row: 1 / span 2;

    // height: 110px;
    padding: 5px 10px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 2px solid #4faaa1;
    margin-right: 10px;
  }

  .final_result_item2 {
    grid-column: 2;
    grid-row: 1;
    padding: 5px 10px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 2px solid #4faaa1;
    margin-right: 10px;
  }

  .final_result_item3 {
    grid-column: 2;
    grid-row: 2;
    padding: 5px 10px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 2px solid #4faaa1;
    margin-right: 10px;
  }
  .item_head {
    font-family: Pretendard_b;
    background-color: #4faaa1;
    border-radius: 15px;
    padding: 5px;
    color: #ffffff;
    margin: 5px 0;
    text-align: center;
    height: 50px;
    font-size: 30px;
  }
  .item_body {
    color: #000000;
    font-family: Pretendard_b;
    padding: 20px 20px 10px 20px;
    .item_txt {
      position: relative;
      margin-bottom: 10px;
      padding-left: 15px;
      line-height: 28px;
      font-size: 20px;
      font-family: "Pretendard_b";
      color: #707070;
      letter-spacing: 0.5px;
    }
    .item_txt::before {
      content: "•";
      position: absolute;
      left: 0;
      top: 0;
      color: #707070;
    }
  }
}

.project_img_box {
  padding: 20px 0 30px;
}
.content_icon.none {
  display:none;
}